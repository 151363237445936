import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import { UserContext } from '../../Contexts/UserProvider/UserProvider';

const User = () => {
    const [ users, setUsers ] = useState( [] );
    const [ refresh, setRefresh ] = useState( false );
    const { user } = useContext( UserContext );
    const { user_role } = user;


    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/user/read/' )
            .then( res => res.json() )
            .then( data => {
                setUsers( data?.body )
            } )
    }, [ refresh ] )


    const handleUser = ( id ) => {
        if ( user?.user_id === id ) {
            Swal.fire(
                'Ooops!',
                'Sorry ! you are not allow to delete yourself',
                'error'
            )
        }
        else {
            Swal.fire( {
                title: 'Are you sure to Delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            } )
                .then( ( result ) => {
                    if ( result.isConfirmed ) {

                        axios.post( 'https://api.zufaa.com/api-zufaa-pos/user/delete/', {
                            "user_id": id
                        } )
                            .then( data => {
                                console.log( data );
                                Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                )

                                setRefresh( !refresh )
                            } )
                    }
                } )
        }


    }




    return (
        <div className='h-auto'>
            {/* Topbar------------ */ }
            <div className='shadow-lg px-10 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-5 items-center'>
                        <h1 className='text-2xl font-semibold'>Users</h1>
                        {
                            user?.user_role === 'Admin' || user?.user_role === 'Manager' ?
                                <Link to='/createuser' className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Create</Link>
                                :
                                <></>
                        }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* Users here--------------- */ }
            <div className=" w-full px-5">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th>SL</th>
                            {/* <th>
                                <label>
                                    <input type="checkbox" className="checkbox" />
                                </label>
                            </th> */}
                            <th>Name</th>
                            <th>Phone</th>
                            {/* <th>Latest authentication</th> */ }
                            <th>Role</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users?.map( ( user, i ) =>
                                <tr key={ i }>
                                    {/* <th>
                                        <label>
                                            <input
                                                type="checkbox" className="checkbox"
                                            />
                                        </label>
                                    </th> */}
                                    <th>{ i + 1 }</th>
                                    <td>{
                                        user?.user_fullname
                                    }</td>
                                    <td>{ user?.user_phone }</td>
                                    {/* <td>11/03/2023  10:45:50</td> */ }
                                    <td>{ user?.user_role }</td>
                                    {/* <td><FaEllipsisV /></td> */ }

                                    <td >
                                        {
                                            user_role === 'Admin' ?
                                                <button
                                                    onClick={ () => handleUser( user?.user_id ) }
                                                    className="rounded-lg cursor-pointer py-1 text-error hover:text-red-600 hover:underline underline-offset-8 flex gap-2 items-center"
                                                >Delete</button>
                                                :
                                                <button disabled className="rounded-lg cursor-pointer text-white px-3 py-2 btn-error btn-disabled-error m-1 flex gap-2 items-center btn-disabled">
                                                    <span>Delete</span>
                                                </button>

                                        }

                                        {/* <button
                                            onClick={ () => handleUser( user?.user_id ) }
                                            className="rounded-lg cursor-pointer py-1 outline-none border-b-2 hover:text-error hover:border-b-error flex gap-2 items-center duration-500"
                                        >Delete</button> */}

                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div >
    );
};

export default User;