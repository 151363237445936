import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';
import { UserContext } from '../../../Contexts/UserProvider/UserProvider';

// const categories = [ 1 ]

const ProductCategory = () => {
    const [ categories, setCategories ] = useState( [] );
    const [ refresh, setRefresh ] = useState( true );
    const [ selectedRows, setSelectedRows ] = useState( [] );
    const { user } = useContext( UserContext );


    const handleCheckbox = ( e, category ) => {
        if ( e.target.checked ) {
            setSelectedRows( [ ...selectedRows, category?.product_category_id ] );
            console.log( category?.product_category_id );

        } else {
            setSelectedRows( selectedRows.filter( ( id ) => id !== category?.product_category_id ) );
            // console.log( category?.product_category_id );
        }

    }
    const selectedData = categories.filter( ( category ) => selectedRows.includes( category?.product_category_id ) );




    const getUrl = 'https://api.zufaa.com/api-zufaa-pos/product-category/'
    useEffect( () => {
        fetch( getUrl, )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setCategories( data );
            } )
    }, [ refresh ] );

    const handleDelete = ( id ) => {
        // console.log( id );
        const data = {
            "product_category_id": id
        }
        const deleteUrl = 'https://api.zufaa.com/api-zufaa-pos/product-category/';

        Swal.fire( {
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        } )
            .then( ( result ) => {
                if ( result.isConfirmed ) {

                    fetch( deleteUrl, {
                        method: 'DELETE',
                        body: JSON.stringify( data )
                    } )
                        .then( res => res.json() )
                        .then( data => {
                            // console.log( data );
                            Swal.fire(
                                `${ data?.message.split( ' ' )[ 0 ] === 'Unable' ? 'Oops...' : 'Deleted' }`,
                                `${ data?.message }`,
                                `${ data?.message.split( ' ' )[ 0 ] === 'Unable' ? 'error' : 'success' }`
                            )
                            setRefresh( !refresh )
                        } )
                }
            } )

    }


    return (
        <div>
            {/* Topbar------------ */ }
            <div className='shadow-lg px-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-4 items-center'>
                        <h1 className='font-semibold'>Product Categories</h1>
                        {
                            user?.user_role === 'Admin' || user?.user_role === 'Manager' ?
                                <Link to='/addCategory' className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Add Category</Link>
                                :
                                <></>
                        }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            <div className=" w-full">
                <div className='bg-[#D9D9D9] flex justify-between pl-5 pr-24 py-5 font-medium'>
                    <div className='flex gap-7 font-semibold items-center'>
                        {/* <input
                            type="checkbox"
                            className="checkbox rounded-sm h-4 w-4 border-black" /> */}
                        <h1>SL</h1>
                        <h1>Product Category</h1>
                    </div>
                    <h1>Action</h1>
                </div>
                {
                    categories?.map( ( category, i ) =>
                        <div key={ i }>
                            <div className=' flex justify-between pl-5 pr-24 font-medium hover:bg-[#EEF8FF]'>
                                <div className='flex gap-10 font-semibold items-center'>
                                    {/* <input
                                        onChange={ ( e ) => handleCheckbox( e, category ) }
                                        type="checkbox"
                                        className="checkbox rounded-sm h-4 w-4 border-black" /> */}
                                    <h1>{ i + 1 }</h1>
                                    <h1 className='font-normal'>{ category?.product_category_name }</h1>
                                </div>


                                {
                                    user?.user_role === 'Admin' ?
                                        <button onClick={ () => handleDelete( category?.product_category_id ) } className="rounded-lg cursor-pointer text-white px-3 py-2 btn-error m-1 flex gap-2 items-center">
                                            <span>Delete</span>
                                        </button>
                                        :
                                        <button disabled className="rounded-lg cursor-pointer text-white px-3 py-2 btn-error btn-disabled-error m-1 flex gap-2 items-center btn-disabled">
                                            <span>Delete</span>
                                        </button>
                                }
                            </div>
                            <hr />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ProductCategory;