import React, { useContext, useEffect, useState } from 'react';
import { FaEllipsisV, FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import { UserContext } from '../../Contexts/UserProvider/UserProvider';



// const customers = [
//     {
//         color: '#DFECDB',
//     },
//     {
//         color: '#AFEDE5'
//     },
//     {
//         color: '#A2CAF9'
//     },
//     {
//         color: '#F2CFBB'
//     },
//     {
//         color: '#AFEDE5'
//     }
// ]

const WareHouse = () => {
    const [ warehouses, setWarehouses ] = useState( [] );
    const [ refresh, setRefresh ] = useState( true );
    const { user } = useContext( UserContext )

    const url = 'https://api.zufaa.com/api-zufaa-pos/warehouse/'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                setWarehouses( data );
            } )
    }, [ refresh ] )

    const handleDelete = ( id ) => {
        const warehouse = { "w_id": id }

        Swal.fire( {
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        } )
            .then( ( result ) => {
                if ( result.isConfirmed ) {

                    fetch( 'https://api.zufaa.com/api-zufaa-pos/warehouse/', {
                        method: 'DELETE',
                        body: JSON.stringify( warehouse )
                    } )
                        .then( res => res.json() )
                        .then( data => {
                            console.log( data );
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            setRefresh( !refresh )
                        } )

                }
            } )
    }

    return (
        <div>
            {/* Topbar add---------------- */ }
            <div className='shadow-lg px-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-8 items-center'>
                        <h1 className='text-xl font-semibold'>Ware House</h1>
                        {
                            user?.user_role === 'Admin' || user?.user_role === 'Manager' ?
                                <Link to='/createWarehouse' className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Create Warehouse</Link>
                                :
                                <></>
                        }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* Content here------------------------ */ }
            <div className='grid grid-cols-3 gap-3 mx-5'>
                {
                    warehouses.map( ( warehouse, i ) =>
                        <div key={ i } className='relative'>
                            <div
                                className='flex gap-2 rounded-lg nav-Shadow items-center border px-5 py-2'>
                                {/* <FaUserAlt className='text-9xl' /> */ }
                                {/* <img src={ img } alt="" className='w-20 rounded-full' /> */ }
                                <div className="avatar">
                                    <div className="w-20">
                                        <FaHome className='text-6xl text-gray-600 opacity-50' />
                                    </div>
                                </div>
                                <div>
                                    <h1 className='font-bold'>{ warehouse?.w_name }</h1>
                                    <p className='text-sm'>{ warehouse?.w_address }</p>
                                </div>
                            </div>
                            <div className="dropdown dropdown-end absolute top-4 right-2">
                                <label tabIndex={ 0 } className='cursor-pointer'> <FaEllipsisV /></label>
                                {
                                    user?.user_role === 'Admin' &&
                                    <div
                                        onClick={ () => handleDelete( warehouse?.w_id ) }
                                        tabIndex={ 0 } className="dropdown-content btn btn-sm btn-error rounded-md p-2 mt-1 shadow pinter">
                                        <h1>Delete</h1>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default WareHouse;