import React, { createContext, useEffect, useState } from 'react';

export const UserContext = createContext()

const UserProvider = ( { children } ) => {
    const [ userId, setUserId ] = useState();
    const [ user, setUser ] = useState( '' );
    const [ loading, setLoading ] = useState( true );

    const id = localStorage.getItem( 'userId' );

    useEffect( () => {
        fetch( `https://api.zufaa.com/api-zufaa-pos/user/read-single/${ id && id }` )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setUser( data && data )
            } )

    }, [ id ] )

    // if ( !user ) {
    //     fetch( `https://api.zufaa.com/api-zufaa-pos/user/read-single/${ id }` )
    //         .then( res => res.json() )
    //         .then( data => {
    //             // console.log( data );
    //             setUser( data && data )
    //         } )
    // }


    const userInfo = { user, userId, setUser, setUserId, loading, setLoading }

    return (
        <UserContext.Provider value={ userInfo }>
            { children }
        </UserContext.Provider>
    );
};

export default UserProvider;