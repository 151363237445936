import React, { useContext, useEffect, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import Swal from 'sweetalert2';
import { ProductsContext } from '../../Contexts/ProductContext';
import { UserContext } from '../../Contexts/UserProvider/UserProvider';

const Dashboard = () => {
    const [ allShop, setAllShop ] = useState( [] );
    const [ refresh, setRefresh ] = useState( true );
    const [ searchShop, setSearchShop ] = useState( [] );
    const { search } = useContext( ProductsContext );
    const { user } = useContext( UserContext );




    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/shop' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                const products = data.filter( product => product?.shop_name.toLowerCase().includes( search.toLowerCase() ) );
                setSearchShop( products )

                setAllShop( data );
            } )
    }, [ refresh, search ] )


    const handleDelete = ( id ) => {
        console.log( id );
        const product = { "shop_id": id }

        Swal.fire( {
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        } )
            .then( ( result ) => {
                if ( result.isConfirmed ) {

                    fetch( 'https://api.zufaa.com/api-zufaa-pos/shop/', {
                        method: 'DELETE',
                        body: JSON.stringify( product )
                    } )
                        .then( res => res.json() )
                        .then( data => {
                            console.log( data );
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            setRefresh( !refresh )
                        } )
                }
            } )
    }


    return (
        <div>
            {/* Topbar------------ */ }
            <div className='shadow-lg pl-5 pr-10 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-5 items-center'>
                        <h1 className='text-2xl font-semibold'>Point of Sale</h1>
                        {
                            user?.user_role === 'Admin' || user?.user_role === 'Manager' ?
                                <Link to='/createShop' className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Add Shop</Link>
                                :
                                <></>
                        }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* Content Here------------ */ }
            <div className='px-10 my-5'>
                <div className='grid grid-cols-2 gap-5'>
                    {/* Shop---------- */ }
                    {
                        searchShop.length ?
                            searchShop?.map( ( shop, i ) =>
                                <div key={ i }>
                                    <div className='border shadow-lg rounded-lg p-5 relative'>
                                        <img src={ shop?.shop_logo } alt="" className='w-56 h-32 object-contain opacity-20 absolute top-5 right-8' />
                                        <div className='flex justify-between'>
                                            <div>
                                                <h1 className='text-xl font-medium'>{ shop?.shop_name }</h1>
                                                <Link to={ `/allpos/${ shop?.shop_id }` } className='btn rounded-md bg-[#1083B2] hover:bg-[#045c7e] border-none mt-5'>NEW SESSION</Link>
                                            </div>

                                            {/* <div className="dropdown dropdown-end cursor-pointer">
                                                <label tabIndex={ 0 } className='cursor-pointer'> <FaEllipsisV /></label>

                                                {
                                                    user?.user_role === "Admin" &&
                                                    <div onClick={ () => handleDelete( shop?.shop_id ) } tabIndex={ 0 } className="dropdown-content btn btn-sm btn-error rounded-md p-2 shadow ">
                                                        <h1>Delete</h1>
                                                    </div>
                                                }
                                            </div> */}

                                            <div className="dropdown dropdown-end absolute top-3 right-3">
                                                <label tabIndex={ 0 } className="hover:cursor-pointer "><FaEllipsisV /></label>
                                                <div tabIndex={ 0 } className="dropdown-content menu shadow bg-[#1083B2] text-white p-2 rounded-md w-40">
                                                    {
                                                        user?.user_role === 'Admin' &&
                                                        <Link onClick={ () => handleDelete( shop?.shop_id ) } className='hover:bg-error hover:bg-opacity-70 duration-500 p-2 rounded-md'>Delete</Link>
                                                    }
                                                    <Link to={ `/updateshop/${ shop?.shop_id }` } className='hover:bg-[#159bcf] duration-500 p-2 rounded-md'>Update Shop</Link>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='mt-7'>
                                            <div className='flex justify-between mr-10'>
                                                <h1>Created Date: </h1>
                                                <p>{ shop?.created_at }</p>
                                            </div>
                                            <div className='flex justify-between mr-10 my-2'>
                                                <h1>Shop Address: </h1>
                                                <p>{ shop?.shop_address }</p>
                                            </div>
                                            <div className='flex justify-between mr-10 my-2'>
                                                <h1>Phone: </h1>
                                                <p>{ shop?.shop_phone }</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                            :

                            allShop?.map( ( shop, i ) =>
                                <div key={ i }>
                                    <div className='border shadow-lg rounded-lg p-5 relative'>
                                        <img src={ shop?.shop_logo } alt="" className='w-56 opacity-20 absolute top-5 right-8' />
                                        <div className='flex justify-between'>
                                            <div>
                                                <h1 className='text-xl font-medium'>{ shop?.shop_name }</h1>
                                                <Link to={ `/allpos/${ shop?.shop_id }` } className='btn rounded-md bg-[#1083B2] border-none mt-5'>NEW SESSION</Link>
                                            </div>

                                            {/* <div className="dropdown dropdown-end">
                                                <label tabIndex={ 0 }> <FaEllipsisV /></label>
                                                <div onClick={ () => handleDelete( shop?.shop_id ) } tabIndex={ 0 } className="dropdown-content btn btn-sm btn-error rounded-md p-2 shadow ">
                                                    <h1>Delete</h1>
                                                </div>
                                                <div onClick={ () => handleDelete( shop?.shop_id ) } tabIndex={ 0 } className="dropdown-content btn btn-sm btn-error rounded-md p-2 shadow ">
                                                    <h1>Delete</h1>
                                                </div>
                                            </div> */}

                                            <div className="dropdown dropdown-end absolute top-2 right-1">
                                                <label tabIndex={ 0 } className="hover:cursor-pointer "><FaEllipsisV /></label>
                                                <div tabIndex={ 0 } className="dropdown-content menu shadow bg-[#1083B2] text-white p-2 rounded-md w-40">
                                                    {
                                                        user?.user_role === 'Admin' &&
                                                        <Link onClick={ () => handleDelete( shop?.shop_id ) } className='hover:bg-error duration-500 p-2 rounded-md'>Delete</Link>
                                                    }
                                                    <Link to={ `/updateshop/${ shop?.shop_id }` } className='hover:bg-[#169dd2] p-2 rounded-md'>Update Shop</Link>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='mt-10'>
                                            <div className='flex justify-between mr-10'>
                                                <h1>Last Closing Date</h1>
                                                <p>06/03/23</p>
                                            </div>
                                            <div className='flex justify-between mr-10 my-2'>
                                                <h1>Last Closing Cash</h1>
                                                <p>$ 0.00</p>
                                            </div>
                                            <div>
                                                <h1>Balance</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                    }

                    {/* Resturent------------------- */ }
                    {/* <Link to='/allpos'>
                        <div className='border shadow-lg rounded-lg p-5 relative'>
                            <img src={ resturent } alt="" className='absolute top-5 right-8' />
                            <div className='flex justify-between'>
                                <div>
                                    <h1 className='text-xl font-medium'>Shop</h1>
                                    <button className='btn rounded-md bg-[#1083B2] border-none mt-5'>NEW SESSION</button>
                                </div>
                                <FaEllipsisV />
                            </div>
                            <div className='mt-10'>
                                <div className='flex justify-between mr-10'>
                                    <h1>Last Closing Date</h1>
                                    <p>06/03/23</p>
                                </div>
                                <div className='flex justify-between mr-10 my-2'>
                                    <h1>Last Closing Cash</h1>
                                    <p>$ 0.00</p>
                                </div>
                                <div>
                                    <h1>Balance</h1>
                                </div>
                            </div>
                        </div>
                    </Link> */}

                </div>
            </div>
        </div>
    );
};

export default Dashboard;