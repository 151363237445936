import React, { useContext } from 'react';
import { FaAngleDown, FaRegFileAlt, FaThLarge, FaUserCircle, FaUsers } from 'react-icons/fa';
import { GiBoxUnpacking, GiHouse } from "react-icons/gi";
import { BiCog, BiLogInCircle } from "react-icons/bi";
// import { ImUser } from "react-icons/im";
import { AiOutlineLogout } from "react-icons/ai";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import NavBar from '../../Shared/NavBar/NavBar';
// import product from '../../Images/Icon/Project Management.png'
import { RiFileList3Fill, RiShieldUserFill } from 'react-icons/ri';
import { UserContext } from '../../Contexts/UserProvider/UserProvider';
import Swal from 'sweetalert2';
import Login from '../../Login/Login';

const DashboardLayout = () => {
    const { user } = useContext( UserContext );
    const navigate = useNavigate();
    // console.log( user );


    const handleLogout = () => {
        Swal.fire( {
            title: 'Do want to Logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No !',
            confirmButtonText: 'Yes!'
        } ).then( ( result ) => {
            if ( result.isConfirmed ) {
                localStorage.removeItem( 'userId' )
                Swal.fire(
                    'Logout Successful!',
                    'Logout',
                    'success'
                )
                navigate( '/' )
                // window.location.reload();
            }
        } )
    }

    return (

        <div>
            {
                user ?
                    <div className='relative'>
                        <div className='w-full sticky top-0 z-50'>
                            <NavBar></NavBar>
                        </div>

                        <div className="drawer drawer-mobile overflow-y-scroll">
                            <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                            <div className="drawer-content flex flex-col">

                                {/* Page content here--------- */ }
                                <Outlet></Outlet>

                                {/* <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden">Open drawer</label> */ }

                            </div>
                            <div className="drawer-side">
                                <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
                                <div className="menu flex flex-col gap-6 bg-[#242D31] py-8 px-6 w-60 text-white">

                                    {/* <!-- Sidebar content here --> */ }
                                    <Link to='/' className='flex gap-3 items-center hover:text-[#1083B2]'>
                                        <FaThLarge className='text-xl' />
                                        <span>Dashboard</span>
                                    </Link>

                                    <div id="accordionExample" className='flex flex-col gap-6'>
                                        <div>
                                            <h2 className="mb-0" id="headingTwo">
                                                <div
                                                    className="group relative flex gap-2 w-full items-center rounded-none border-0 text-left text-base transition hover:text-[#1083B2]"
                                                    type="button"
                                                    data-te-collapse-init
                                                    data-te-collapse-collapsed
                                                    data-te-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo">
                                                    <FaUsers className='text-3xl' />
                                                    <button className='flex justify-between items-center w-full' >
                                                        <span>User</span>
                                                        <FaAngleDown />
                                                    </button>
                                                </div>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className="!visible hidden"
                                                data-te-collapse-item
                                                aria-labelledby="headingTwo"
                                                data-te-parent="#accordionExample">
                                                <div className="bg-[#181f21] rounded-lg py-2 mt-2">
                                                    <Link to='/users' className='hover:text-[#1083B2] flex mt-2 rounded-lg px-4 '>Users</Link>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div id="accordionExample"> */ }
                                        <div>
                                            <h2 className="mb-0" id="headingThree">
                                                <div
                                                    className="group relative flex gap-2 w-full items-center rounded-none border-0 text-left text-base transition hover:text-[#1083B2]"
                                                    type="button"
                                                    data-te-collapse-init
                                                    data-te-collapse-collapsed
                                                    data-te-target="#collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                    <RiShieldUserFill className='text-3xl' />
                                                    <button className='flex justify-between items-center w-full' >
                                                        <span>Customers</span>
                                                        <FaAngleDown />
                                                    </button>
                                                </div>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className="!visible hidden"
                                                data-te-collapse-item
                                                aria-labelledby="headingThree"
                                                data-te-parent="#accordionExample">
                                                <div className="bg-[#181f21] rounded-lg py-2 mt-2">
                                                    <Link to='/customers' className='hover:text-[#1083B2]  flex mt-2 rounded-lg px-4 '>Customers</Link>
                                                    {/* <Link to='/createCustomer' className='hover:text-[#1083B2]  flex mt-2 rounded-lg px-4 '>Create Customer</Link> */ }
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */ }

                                        {/* <div id="accordionExample"> */ }
                                        <div>
                                            <h2 className="mb-0" id="headingFour">
                                                <div
                                                    className="group relative flex gap-2 w-full items-center rounded-none border-0 text-left text-base transition hover:text-[#1083B2]"
                                                    type="button"
                                                    data-te-collapse-init
                                                    data-te-collapse-collapsed
                                                    data-te-target="#collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="collapseFour">
                                                    {/* <img className='w-7' src={ product } alt="" /> */ }
                                                    <GiBoxUnpacking className='text-3xl' />
                                                    <button className='flex justify-between items-center w-full' >
                                                        <span>Product</span>
                                                        <FaAngleDown />
                                                    </button>
                                                </div>
                                            </h2>
                                            <div
                                                id="collapseFour"
                                                className="!visible hidden"
                                                data-te-collapse-item
                                                aria-labelledby="headingFour"
                                                data-te-parent="#accordionExample">
                                                <div className="bg-[#181f21] rounded-lg py-2 mt-2">
                                                    <Link to='/product' className='hover:text-[#1083B2]  flex mt-2 rounded-lg px-4 '>All Product</Link>
                                                    <Link to='/productCategory' className='hover:text-[#1083B2]  flex mt-2 rounded-lg px-4 '>Product Category</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */ }

                                        {/* <div id="accordionExample"> */ }
                                        <div>
                                            <h2 className="mb-0" id="headingFive">
                                                <div
                                                    className="group relative flex gap-2 w-full items-center rounded-none border-0 text-left text-base transition hover:text-[#1083B2]"
                                                    type="button"
                                                    data-te-collapse-init
                                                    data-te-collapse-collapsed
                                                    data-te-target="#collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="collapseFive">
                                                    {/* <FaWarehouse className='text-2xl' /> */ }
                                                    <GiHouse className='text-3xl' />
                                                    <button className='flex justify-between items-center w-full' >
                                                        <span>Warehouse</span>
                                                        <FaAngleDown />
                                                    </button>
                                                </div>
                                            </h2>
                                            <div
                                                id="collapseFive"
                                                className="!visible hidden"
                                                data-te-collapse-item
                                                aria-labelledby="headingFive"
                                                data-te-parent="#accordionExample">
                                                <div className="bg-[#181f21] rounded-lg py-2 mt-2">
                                                    <Link to='/warehouse' className='hover:text-[#1083B2] flex mt-2 rounded-lg px-4'>Ware House</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */ }


                                        {/* Order--------- */ }
                                        {/* <div className="dropdown dropdown-bottom dropdown-end">
                            <label tabIndex={ 0 } className="bg-transparent border-none w-full flex justify-between items-center">
                                <div className='flex gap-2 items-center'>
                                    <img className='w-7' src={ order } alt="" />
                                    <span>Order</span>
                                </div>
                                <FaAngleDown />
                            </label>
                            <ul tabIndex={ 0 } className="dropdown-content  menu p-2 shadow bg-[#242D31] rounded-box w-52">
                                <li>
                                    <Link to='/createuser' className='hover:bg-[#1083B2] border'>Create User</Link>
                                </li>
                            </ul>
                        </div> */}
                                        {/* <div id="accordionExample"> */ }

                                        <div>
                                            <h2 className="mb-0" id="headingSix">
                                                <div
                                                    className="group relative flex gap-2 w-full items-center rounded-none border-0 text-left text-base transition hover:text-[#1083B2]"
                                                    type="button"
                                                    data-te-collapse-init
                                                    data-te-collapse-collapsed
                                                    data-te-target="#collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="collapseSix">
                                                    {/* <img className='w-8' src={ order } alt="" /> */ }
                                                    <RiFileList3Fill className='text-3xl' />
                                                    <button className='flex justify-between items-center w-full' >
                                                        <span>Order</span>
                                                        <FaAngleDown />
                                                    </button>
                                                </div>
                                            </h2>
                                            <div
                                                id="collapseSix"
                                                className="!visible hidden"
                                                data-te-collapse-item
                                                aria-labelledby="headingSix"
                                                data-te-parent="#accordionExample">
                                                <div className="bg-[#181f21] rounded-lg py-2 mt-2">
                                                    <Link to='/allorders' className='hover:text-[#1083B2]  flex mt-2 rounded-lg px-4'>All Orders</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h2 className="mb-0" id="headingSeven">
                                                <div
                                                    className="group relative flex gap-2 w-full items-center rounded-none border-0 text-left text-base transition hover:text-[#1083B2]"
                                                    type="button"
                                                    data-te-collapse-init
                                                    data-te-collapse-collapsed
                                                    data-te-target="#collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="collapseSeven">
                                                    <FaRegFileAlt className='text-2xl' />
                                                    <button className='flex justify-between items-center w-full' >
                                                        <span>Report</span>
                                                        <FaAngleDown />
                                                    </button>
                                                </div>
                                            </h2>
                                            <div
                                                id="collapseSeven"
                                                className="!visible hidden"
                                                data-te-collapse-item
                                                aria-labelledby="headingSeven"
                                                data-te-parent="#accordionExample">
                                                <div className="bg-[#181f21] rounded-lg py-2 mt-2">
                                                    <Link to='/report' className='hover:text-[#1083B2] flex mt-2 rounded-lg px-4'>Report</Link>
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            <h2 className="mb-0" id="headingEight">
                                                <div
                                                    className="group relative flex gap-2 w-full items-center rounded-none border-0 text-left text-base transition hover:text-[#1083B2]"
                                                    type="button"
                                                    data-te-collapse-init
                                                    data-te-collapse-collapsed
                                                    data-te-target="#collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="collapseEight">
                                                    {/* <ImUser className='text-2xl rounded-full w-7 h-2w-7 border' /> */ }
                                                    <FaUserCircle className='text-3xl' />
                                                    <button className='flex justify-between items-center w-full' >
                                                        <span>Account</span>
                                                        <FaAngleDown />
                                                    </button>
                                                </div>
                                            </h2>
                                            <div
                                                id="collapseEight"
                                                className="!visible hidden"
                                                data-te-collapse-item
                                                aria-labelledby="headingEight"
                                                data-te-parent="#accordionExample">
                                                <div className="">
                                                    {/* <Link className='hover:text-[#1083B2] flex mt-2 rounded-lg px-4'>Account Details</Link> */ }
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h2 className="mb-0" id="headingNine">
                                                <div
                                                    className="group relative flex gap-2 w-full items-center rounded-none border-0 text-left text-base transition hover:text-[#1083B2]"
                                                    type="button"
                                                    data-te-collapse-init
                                                    data-te-collapse-collapsed
                                                    data-te-target="#collapseNine"
                                                    aria-expanded="false"
                                                    aria-controls="collapseNine">
                                                    <BiCog className='text-3xl' />
                                                    <button className='flex justify-between items-center w-full' >
                                                        <span>Setting</span>
                                                        <FaAngleDown />
                                                    </button>
                                                </div>
                                            </h2>
                                            <div
                                                id="collapseNine"
                                                className="!visible hidden"
                                                data-te-collapse-item
                                                aria-labelledby="headingNine"
                                                data-te-parent="#accordionExample">

                                                {
                                                    user?.user_role === 'Admin' || user?.user_role === 'Manager' ?

                                                        <div className="bg-[#181f21] rounded-lg py-2 mt-2">
                                                            <Link to='/addtax' className='hover:text-[#1083B2] flex mt-2 rounded-lg px-4'>Add Tax</Link>
                                                            <Link to='/updateTax' className='hover:text-[#1083B2] flex mt-2 rounded-lg px-4'>Update Tax</Link>
                                                            <Link to='/addDiscount' className='hover:text-[#1083B2] flex mt-2 rounded-lg px-4'>Add Discount</Link>
                                                            <Link to='/updateDiscount' className='hover:text-[#1083B2] flex mt-2 rounded-lg px-4'>Update Discount</Link>
                                                        </div>
                                                        :
                                                        <></>
                                                }

                                            </div>
                                        </div>



                                    </div>



                                    {/* <Link to='/' className='flex gap-3 items-center'>
                            <BiCog className='text-white text-2xl' />
                            <span>Setting</span>
                        </Link> */}

                                    {/* Logout---------------- */ }

                                    {
                                        user ?
                                            <Link onClick={ handleLogout } className='flex gap-3 items-center hover:text-[#1083B2]'>
                                                <AiOutlineLogout className='text-2xl font-bold' />
                                                <span>Logout</span>
                                            </Link>
                                            :
                                            <Link to='/login' className='flex gap-3 items-center hover:text-[#1083B2]'>
                                                <BiLogInCircle className='text-2xl font-bold' />
                                                <span>Log in</span>
                                            </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Login></Login>
            }
        </div>

    );
};

export default DashboardLayout;