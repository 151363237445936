import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import noImage from '../../Images/UseImage/noImage.png'

const ProductVarient = () => {
    const { id } = useParams()
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [ product, setProduct ] = useState()

    // get Single Porduct--------------
    useEffect( () => {
        fetch( `https://api.zufaa.com/api-zufaa-pos/product/${ id }` )
            .then( res => res.json() )
            .then( data => {
                setProduct( data );
            } )
    }, [ id ] );


    const onSubmit = data => {

        const variant = {
            "variant_name": data?.name,
            "variant_image": product?.product_name,
            "product_id": product?.product_id
        }
        reset()
        console.log( variant );
    }


    return (
        <div>
            {/* Topbar------------ */ }
            <div className='shadow-lg pl-5 pr-10 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-2 items-center'>
                        <Link to='/product' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-xl font-semibold'>Product / Inventory</h1>
                        <div className='btn btn-sm rounded-md bg-[#1083B2] border-none'>save</div>
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>
            <form
                onSubmit={ handleSubmit( onSubmit ) }
                className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                <h1 className='text-xl font-semibold'>Add to Inventory</h1>
                <hr className='my-2 border-b-2' />
                <div className=' flex justify-center items-center gap-3 mt-10 '>
                    <div className='flex flex-col gap-6 w-2/3'>

                        <div className='flex justify-between items-end '>
                            <span className='text-lg font-normal'>Variant Name: </span>
                            <input type="text" placeholder='Variant Name' className='border-b-2 py-2 w-3/4 focus:outline-none '
                                { ...register( "name" ) }
                            />
                        </div>
                        { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                    </div>

                    <div className='flex flex-col gap-2 items-center justify-center w-1/3'>
                        <img src={ product?.product_image || noImage } alt="" className='w-48 h-48 object-cover border rounded-lg' />
                        <div>
                            <h1>{ product?.product_name }</h1>
                            <h1>Price: { product?.product_selling_price }</h1>
                        </div>
                    </div>
                </div>

                <input type="submit" value="Create" className='btn w-full hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
                {/* <button className='btn btn-wide rounded-md bg-[#1083B2] border-none mt-5'>Save</button> */ }
            </form>
        </div>
    );
};

export default ProductVarient;