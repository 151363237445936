import React, { useEffect, useState } from 'react';
import { FaAngleDoubleLeft, FaCloudUploadAlt } from 'react-icons/fa';
import empty from '../../../Images/UseImage/41190_empty_256x256.png'
import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const AddProduct = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    // const [ selectedFile, setSelectedFile ] = useState( null );
    const [ previewUrl, setPreviewUrl ] = useState( null );
    const [ productName, SetProductName ] = useState( '' )
    const [ categories, setCategories ] = useState( [] )
    const [ productImg, setProductImg ] = useState( '' );


    const onSubmit = data => {

        // const product = {
        //     "product_name": data?.name,
        //     "product_description": data?.description,
        //     "product_image": "https://i.ibb.co/sb5zcZP/50-502509-tube-boisson-cocktail-png-cocktail-png-transparent-png.png",
        //     "product_buying_price": data?.BuyPrice,
        //     "product_selling_price": data?.sellPrice,
        //     "product_category_id": data?.category_id
        // }
        // console.log(product);

        if ( productImg ) {
            const url = ' https://api.zufaa.com/api-zufaa-pos/product/';

            axios.post( url, {
                "product_name": data?.name,
                "product_description": data?.description,
                "product_image": productImg && productImg,
                "product_buying_price": data?.BuyPrice,
                "product_selling_price": data?.sellPrice,
                "product_category_id": data?.category_id
            } )

                .then( res => {
                    Swal.fire(
                        'Good job!',
                        'Product Added',
                        'success'
                    )
                    reset()
                    setPreviewUrl( null )
                    // console.log( res.data );
                } )
                .catch( error => {
                    console.log( error );
                } )
        }
        else {
            Swal.fire(
                'Ooops!',
                'Please Upload Product Image',
                'error'
            )
        }


    };


    // image upload and show on client side------------
    const handleImage = ( e ) => {
        const formData = new FormData();
        const image = e.target.files[ 0 ];

        formData.append( "file", image );


        const url = 'https://api.zufaa.com/api-zufaa-pos/product/upload/';
        fetch( url, {
            method: "POST",
            body: formData,
        } )
            .then( ( response ) => response.json() )
            .then( ( result ) => {

                setProductImg( result[ 0 ] )
                if ( !result[ 0 ] ) {
                    setPreviewUrl( null );
                    SetProductName( '' )
                    return;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    setPreviewUrl( reader.result );
                    if ( image.name.length > 15 ) {
                        SetProductName( image.name.slice( 0, 10 ) + '...' );
                    }
                };
                reader.readAsDataURL( image );

            } )
            .catch( ( error ) => {
                console.error( "Error:", error );
            } );



    }


    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/product-category/' )
            .then( res => res.json() )
            .then( data => {
                // console.log(data);
                setCategories( data );
            } )
    }, [] )


    return (
        <div>
            <div className='shadow-lg pr-8 pl-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <Link to='/product' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-2xl font-semibold'>Add Product</h1>
                        {/* <button to='/addProduct' className='btn btn-sm rounded-md bg-[#1083B2] border-none'>save</button> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/*Add Categories---------------- */ }
            <form onSubmit={ handleSubmit( onSubmit ) } className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                <div className='flex justify-end items-end gap-3'>
                    <div className='flex flex-col gap-6 w-2/3'>
                        <div>
                            <label htmlFor="">
                                Product Name
                            </label>
                            <input type="text" placeholder='Product Name' className='border rounded-lg mt-1 w-full px-4 py-2 focus:outline-none '
                                { ...register( "name",
                                    { required: "Name is Required" }
                                ) } />
                            { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                        </div>

                        <div className='flex gap-4 w-full'>
                            <div className='w-full'>
                                <label htmlFor="">Buying Price</label>
                                <input type="number" step="any" placeholder='Product_Buying_Price' className='border rounded-lg mt-1 w-full px-4 py-2 focus:outline-none '
                                    { ...register( "BuyPrice",
                                        { required: "BuyPrice Required" }
                                    ) } />
                                { errors.BuyPrice && <p className='text-error'>{ errors.BuyPrice?.message }</p> }
                            </div>
                            <div className='w-full'>
                                <label htmlFor="">Selling Price</label>
                                <input type="number" step="any" placeholder='Product Selling Price' className='border rounded-lg mt-1 w-full px-4 py-2 focus:outline-none '
                                    { ...register( "sellPrice",
                                        { required: 'Price Required' }
                                    ) }
                                />
                                { errors.sellPrice && <p className='text-error'>{ errors.sellPrice?.message }</p> }
                            </div>
                        </div>
                        <div>

                            <select { ...register( "category_id",
                                { required: 'Category Id Required!' }
                            ) }
                                className='border rounded-lg mt-1 w-full px-4 py-2 focus:outline-none '
                            // defaultValue='Product Category'
                            >
                                <option value=''>Product Category</option>
                                {
                                    categories?.map( ( category, i ) => <option value={ category?.product_category_id } key={ i }>{ category?.product_category_name }</option> )
                                }

                            </select>
                            { errors.category_id && <p className='text-error'>{ errors.category_id?.message }</p> }
                        </div>

                        <div>
                            <label htmlFor="">Description</label>
                            <textarea name="" id="" placeholder='product Description' className='border rounded-lg h-24 mt-1 w-full px-4 py-2 focus:outline-none '
                                { ...register( "description",
                                    { required: 'Description is Required !' }
                                ) }
                            />

                            { errors.description && <p className='text-error'>{ errors.description?.message }</p> }
                        </div>

                    </div>

                    <div className='w-1/3 mx-auto flex gap-2 flex-col'>
                        <div className=' flex flex-col gap-3'>
                            {
                                previewUrl ?
                                    <img className='border w-full h-72 object-cover rounded-lg ' src={ previewUrl } alt="" />
                                    :
                                    <div>
                                        <img className='border w-full h-72 object-cover rounded-lg ' src={ empty } alt="" />
                                        <p className='text-accent text-center'>Only allowed  png, jpg, and jpeg</p>
                                    </div>

                            }
                            <label htmlFor="upload-photo" className='flex gap-2 items-center border rounded-md justify-center'>
                                { productName ? productName : 'Upload Product Photo' }
                                {/* Upload Your Photo */ }
                                <FaCloudUploadAlt className='text-4xl rounded-lg' />
                            </label>
                            <input onChange={ ( e ) => handleImage( e ) } type="file" id="upload-photo" className='hidden'
                            // { ...register( "image", { required: 'Image is Required !' } ) }
                            />
                            {/* { errors.image && <p className='text-error'>  { errors.image?.message }</p> } */ }
                        </div>

                    </div>
                </div>
                <input type="submit" value="Save" className='btn w-full hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
            </form>
        </div>
    );
};

export default AddProduct;