import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';

const AddDiscount = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    const onSubmit = data => {
        const dis_percent = data.discount_percentage / 100;

        const Dis = {
            "amount": dis_percent,
            "remarks": data.name
        }
        console.log( Dis );
        const url = 'https://api.zufaa.com/api-zufaa-pos/discount/'
        axios.post( url,
            {
                "amount": dis_percent,
                "remarks": data.name
            }
        )
            .then( res => {
                Swal.fire(
                    'Good job!',
                    'Discount_Percentage Added',
                    'success'
                )
                reset()
            } )
            .then( error => {
                console.log( error );
            } )


    }





    return (
        <div>
            {/* Topbar add---------------- */ }
            <div className='shadow-lg px-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <Link to='/' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-xl font-semibold'>Add Tax</h1>
                        {/* <button className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Save</button> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>


            <form onSubmit={ handleSubmit( onSubmit ) } className='px-8 py-12 mx-5 rounded-lg nav-Shadow flex flex-col justify-center'>
                <div className=' flex gap-3'>
                    <div className='flex flex-col gap-6 w-1/2 mx-auto'>
                        <div>
                            <label htmlFor="">Discount Name:</label>
                            <input type="text" placeholder='Enter Discount Name' className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "name",
                                { required: "Discount Name" }
                            ) } />
                            { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                        </div>
                        <div>
                            <label htmlFor="">Discount percentage:</label>
                            <input type="number" placeholder='Enter Discount percentage' className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "discount_percentage",
                                { required: 'Discount percentage required' }
                            ) } />
                            { errors.discount_percentage && <p className='text-error'>{ errors.discount_percentage?.message }</p> }
                        </div>

                    </div>

                </div>
                <input type="submit" value="Add Discount" className='btn w-1/2 mx-auto hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
                {/* <button className='btn btn-wide rounded-md bg-[#1083B2] border-none mt-5'>Save</button> */ }
            </form>

        </div>
    );
};

export default AddDiscount;