import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../../Shared/NavBar/NavBar';
import ProductNav from '../../Shared/ProductNav/ProductNav';
import ProductCart from '../../Shared/ProductCart/ProductCart';

const POSLayout = () => {


    return (
        <div className='relative bg-[#F5F5F5]'>
            <div className='w-full sticky top-0 z-50'>
                <NavBar></NavBar>
            </div>

            <div className='flex justify-between py-4 gap-2 shadow-lg '>

                {/* Left Side--------------------- */ }
                <div className='w-2/3 overflow-y-scroll max-h-[770px]'>

                    {/* Product Navbar------------- */ }
                    <ProductNav></ProductNav>

                    <Outlet></Outlet>
                </div>

                {/* Right Side-------------- */ }
                <div className='w-1/3'>
                    <div className='w-full nav-Shadow rounded-b-lg '>
                        <ProductCart></ProductCart>
                    </div>
                </div>

            </div>

        </div >

    );
};

export default POSLayout;