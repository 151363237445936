import React, { useEffect, useState } from 'react';
import { FaAngleDoubleLeft, FaCloudUploadAlt } from 'react-icons/fa';
import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';
import empty from '../../../Images/UseImage/images (3).jpg'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CreateShop = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [ warehouses, setWarehouses ] = useState( [] )
    const [ productName, SetProductName ] = useState( '' );
    const [ userImg, setUserImg ] = useState( '' )
    // const [ selectedFile, setSelectedFile ] = useState( null );
    const [ previewUrl, setPreviewUrl ] = useState( null );
    const navigate = useNavigate()



    const handleImage = ( e ) => {
        const formData = new FormData();
        const image = e.target.files[ 0 ];
        formData.append( "file", image );
        // setSelectedFile( image );

        const url = ' https://api.zufaa.com/api-zufaa-pos/shop/upload/';
        fetch( url, {
            method: "POST",
            body: formData,
        } )
            .then( ( response ) => response.json() )
            .then( ( result ) => {
                setUserImg( result[ 0 ] )

                if ( !result[ 0 ] ) {
                    setPreviewUrl( null );
                    return;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    setPreviewUrl( reader.result );
                    if ( image.name.length > 15 ) {
                        SetProductName( image.name.slice( 0, 10 ) + '...' );
                        console.log( image.name.slice( 0, 15 ) + '...' );
                    }
                };
                reader.readAsDataURL( image );
            } )
            .catch( ( error ) => {
                console.error( "Error:", error );
            } );


    }

    const onSubmit = data => {

        // const user =
        // {
        //     "shop_name": data?.name,
        //     "shop_address": data?.address,
        //     "shop_phone": data?.phone,
        //     "shop_logo": userImg,
        //     "w_id": data?.warehouse_id
        // }
        // console.log( user );

        const userUrl = 'https://api.zufaa.com/api-zufaa-pos/shop/';

        axios.post( userUrl, {
            "shop_name": data?.name,
            "shop_address": data?.address,
            "shop_phone": data?.phone,
            "shop_logo": userImg,
            "w_id": data?.warehouse_id
        } )
            .then( data => {
                console.log( data );
                Swal.fire(
                    'Good job!',
                    // `${ data?.name } is Created`,
                    'New Shop is Created',
                    'success'
                )
                navigate( '/' )
                reset()
            } )
            .then( error => {
                console.log( error );
            } )

    }

    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/warehouse/' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setWarehouses( data );
            } )
    }, [] )



    return (
        <div>
            {/* Topbar add---------------- */ }
            <div className='shadow-lg px-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <Link to='/' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-xl font-semibold'>Create Shop</h1>
                        {/* <button className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Save</button> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* Shop content here-------------- */ }
            <form onSubmit={ handleSubmit( onSubmit ) } className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                <div className=' flex gap-3'>
                    <div className='flex flex-col gap-6 w-2/3'>
                        <div>
                            <input type="text" placeholder='Shop Name' className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "name",
                                { required: "Shop Name is Required" }
                            ) } />
                            { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                        </div>
                        <div>
                            <input type="text" placeholder='Address' className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "address",
                                { required: 'Address is Required' }
                            ) } />
                            { errors.address && <p className='text-error'>{ errors.address?.message }</p> }
                        </div>
                        <div>
                            <input type="number" placeholder='Phone Number' className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "phone",
                                { required: 'Phone is Required' }
                            ) } />
                            { errors.phone && <p className='text-error'>{ errors.phone?.message }</p> }
                        </div>

                        {/* Get all WareHouse-------------------- */ }
                        <div>
                            <select { ...register( "warehouse_id",
                                { required: 'Warehouse Id Required!' }
                            ) }
                                className='border-b-2 mt-1 w-full px-4 py-2 focus:outline-none '
                            // defaultValue='Product Category'
                            >
                                <option value=''>Select Warehouse</option>
                                {
                                    warehouses?.map( ( warehouse, i ) => <option value={ warehouse?.w_id } key={ i }>{ warehouse?.w_name }</option> )
                                }

                            </select>
                            { errors.category_id && <p className='text-error'>{ errors.category_id?.message }</p> }
                        </div>

                    </div>

                    <div className='w-1/3 mx-auto flex gap-2 flex-col justify-center items-center'>
                        <div className=' flex flex-col gap-3 w-60 '>

                            {/* <img className='border rounded-lg ' src={ chair } alt="" /> */ }
                            { previewUrl ?
                                <img className='border object-cover p-2 rounded-lg ' src={ previewUrl } alt="" />
                                :
                                <div>
                                    <img className='border object-cover p-2 rounded-lg ' src={ empty } alt="" />
                                    <p className='text-error text-center'>Only allowed  png, jpg, and jpeg</p>
                                </div>
                            }
                            <label htmlFor="upload-photo" className='flex gap-2 items-center cursor-pointer border rounded-md justify-center'>
                                { productName ? productName : 'Upload Your Logo' }
                                <FaCloudUploadAlt className='text-4xl  rounded-lg' />
                            </label>
                            <input onChange={ handleImage } type="file" name="photo" id="upload-photo" className='hidden' />
                            {/* <input type="file" name="" id="" /> */ }
                        </div>

                    </div>
                </div>
                <input type="submit" value="Create" className='btn w-full hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
            </form>
        </div>
    );
};

export default CreateShop;