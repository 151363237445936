import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';
import React, { useContext, useEffect, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { UserContext } from '../../../Contexts/UserProvider/UserProvider';

// const customers = [
//     {
//         color: '#DFECDB',
//     },
//     {
//         color: '#AFEDE5'
//     },
//     {
//         color: '#A2CAF9'
//     },
//     {
//         color: '#F2CFBB'
//     },
//     {
//         color: '#AFEDE5'
//     }
// ]

const Customers = () => {
    const [ customers, setCustomers ] = useState( [] );
    const [ refresh, setRefresh ] = useState( true );
    const { user } = useContext( UserContext )

    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/customer/' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setCustomers( data )
            } )
    }, [ refresh ] )


    const handleDelete = ( id ) => {

        const product = { "customer_id": id }

        Swal.fire( {
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        } )
            .then( ( result ) => {
                if ( result.isConfirmed ) {

                    fetch( 'https://api.zufaa.com/api-zufaa-pos/customer/', {
                        method: 'DELETE',
                        body: JSON.stringify( product )
                    } )
                        .then( res => res.json() )
                        .then( data => {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            setRefresh( !refresh )
                        } )

                }
            } )
    }


    return (
        <div>
            <div className='shadow-lg pl-5 pr-8 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-5 items-center'>
                        <h1 className='text-2xl font-semibold'>Customers</h1>
                        <Link to='/createCustomer' className='btn btn-sm rounded-md bg-[#1083B2] border-none'>New</Link>
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>
            {/* Customers------------------ */ }
            <div className='grid grid-cols-3 gap-3 mx-5'>
                {
                    customers.map( ( customer, i ) =>
                        <div key={ i } className='relative'>
                            <div
                                style={ { backgroundColor: `${ customer.color }` } } className='flex gap-4 rounded-lg nav-Shadow items-center  border px-5 py-2'>
                                {/* <FaUserAlt className='text-9xl' /> */ }

                                {/* <div className="avatar">
                                    <div className="w-20 rounded-full ">
                                        <img src={ img } alt='' />

                                    </div>
                                </div> */}

                                <h1 className='flex justify-center items-center my-auto text-5xl p-3 rounded-full bg-slate-200 w-20 h-20 font-bold'>{ customer?.customer_name.split( '' )[ 0 ] }</h1>

                                <div>
                                    <h1 className='font-bold'>{ customer?.customer_name
                                    }</h1>
                                    {/* <p className='text-sm'>01837346129</p> */ }
                                    <p className='text-sm'>{ customer?.customer_email }</p>
                                </div>
                            </div>

                            <div className="dropdown dropdown-end absolute top-4 right-2">
                                <label tabIndex={ 0 } className='cursor-pointer'> <FaEllipsisV /></label>
                                {
                                    user?.user_role === 'Admin' &&
                                    <div
                                        onClick={ () => handleDelete( customer?.customer_id ) }
                                        tabIndex={ 0 } className="dropdown-content btn btn-sm btn-error rounded-md p-2 mt-1 shadow pinter">
                                        <h1>Delete</h1>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Customers;