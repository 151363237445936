import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import noImage from '../../../Images/UseImage/noImage.png'

const SingleProduct = ( { product } ) => {

    // console.log( product );


    return (
        <div className='border rounded-lg shadow-lg p-2 relative'>
            <div className='flex gap-2 '>
                <img src={ product?.product_image || noImage } alt="" className='w-24 h-24 object-cover border rounded-lg' />
                <div>
                    <h1 className='font-normal'>{ product?.product_name }</h1>
                    <p>{ product?.product_description }</p>
                    <p>Buying Price: ৳ { product?.product_buying_price }</p>
                    <p>Selling Price: ৳ { product?.product_selling_price }</p>
                </div>
                <div className="dropdown dropdown-end absolute top-2 right-1">
                    <label tabIndex={ 0 } className="hover:cursor-pointer "><FaEllipsisV /></label>
                    <div tabIndex={ 0 } className="dropdown-content menu shadow bg-[#1083B2] text-white p-2 rounded-md w-40">
                        <Link to={ `/inventory/${ product?.product_id }` } className='hover:bg-[#169dd2] p-2 rounded-md'>Add to Inventory</Link>
                        <Link to={ `/variant/${ product?.product_id }` } className='hover:bg-[#169dd2] p-2 rounded-md'>Product Variant</Link>
                        {/* <button className='hover:bg-[#f46b6b] p-2 rounded-md text-left'>Delete</button> */ }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleProduct;