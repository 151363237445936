import React, { createContext, useState } from 'react';

export const ProductsContext = createContext()

const ProductContext = ( { children } ) => {
    // const [ ShopId, setProductsId ] = useState();
    const [ search, setSearch ] = useState( '' );
    const [ refresh, setRefresh ] = useState( true );
    const [ warehouse, setWareHouse ] = useState( '' )

    // const productInfo = { ShopId, setProductsId, search, setSearch, refresh, setRefresh, warehouse, setWareHouse }
    const productInfo = { search, setSearch, refresh, setRefresh, warehouse, setWareHouse }
    return (
        <ProductsContext.Provider value={ productInfo }>
            { children }
        </ProductsContext.Provider>
    );
};

export default ProductContext;