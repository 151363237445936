import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Product from '../../../Component/Product/Product';


// const products = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ]

const SingleCategoryProducts = () => {
    const [ products, setProducts ] = useState( [] )
    const { ShopId } = useParams();


    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/product/' )
            .then( res => res.json() )
            .then( data => {

                // Get all category product-------------
                const allProducts = data.filter( product => product?.product_category_id === ShopId );

                fetch( 'https://api.zufaa.com/api-zufaa-pos/inventory/' )
                    .then( res => res.json() )
                    .then( allInventory => {

                        // Get all product of inventory added------------------------
                        const filteredProducts = allProducts.filter( product => allInventory.find( inventory => product?.product_id === inventory?.product_id ) );

                        setProducts( filteredProducts )
                    } )
            } )
    }, [ ShopId ] );


    // useEffect( () => {
    //     fetch( `https://api.zufaa.com/api-zufaa-pos/shop/${ shopId }` )
    //         .then( res => res.json() )
    //         .then( data => {
    //             console.log( data );
    //         } )
    // }, [ shopId ] )


    return (
        <div className=' ml-5 mt-5 p-3 rounded-lg'>
            {
                products?.length ?
                    <div className='grid grid-cols-4 gap-8'>
                        {
                            products.map( ( product, i ) => <Product key={ i } product={ product } ShopId={ ShopId }></Product> )
                        }
                    </div>
                    :
                    <div>
                        <h1 className='text-3xl text-center font-bold'>No Product Added Yet !</h1>
                    </div>
            }
        </div>
    );
};

export default SingleCategoryProducts;