import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';

const CreateWarehouse = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    const onSubmit = data => {

        const url = 'https://api.zufaa.com/api-zufaa-pos/warehouse/'
        axios.post( url,
            {
                "w_name": data?.name,
                "w_address": data?.address
            }
        )
            .then( res => {
                Swal.fire(
                    'Good job!',
                    `${ data?.name } is Created`,
                    'success'
                )
                reset()
            } )
            .then( error => {
                // console.log( error );
            } )




    }

    return (
        <div>
            {/* Topbar add---------------- */ }
            <div className='shadow-lg px-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <Link to='/warehouse' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-xl font-semibold'>Warehouse</h1>
                        {/* <button className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Save</button> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>


            {/* Content here------------ */ }
            <form onSubmit={ handleSubmit( onSubmit ) } className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                <div className=' flex gap-3'>
                    <div className='flex flex-col gap-6 w-full'>
                        <div>
                            <input type="text" placeholder='Warehouse Name' className='border-b-2 w-1/2 px-4 py-2 focus:outline-none ' { ...register( "name",
                                { required: "Name is Required" }
                            ) } />
                            { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                        </div>
                        <div>
                            <input type="text" placeholder='Address' className='border-b-2 w-1/2 px-4 py-2 focus:outline-none ' { ...register( "address",
                                { required: 'Address is Required' }
                            ) } />
                            { errors.address && <p className='text-error'>{ errors.address?.message }</p> }
                        </div>

                    </div>

                </div>
                <input type="submit" value="Create" className='btn w-full hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
                {/* <button className='btn btn-wide rounded-md bg-[#1083B2] border-none mt-5'>Save</button> */ }
            </form>
        </div>
    );
};

export default CreateWarehouse;