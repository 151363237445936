import { format } from 'date-fns';
import React from 'react';
import Barcode from 'react-barcode';


const InvoicePdf = ( { user, allProduct, order, payMethod, payCash, change, date, today, shop } ) => {


    return (
        <div className='p-2'>
            <div className='border rounded-lg p-2'>
                <div className='text-center text-[#606060]'>
                    {/* <h1 className='text-2xl font-bold'>Your Logo</h1> */ }
                    <img src='https://images.zufaa.com/shop/641e8210e0b5e.png' alt="" className='flex justify-center w-10 mx-auto' />
                    <p>{ shop?.shop_name }</p>
                    {/* <p>Address: { shop?.shop_address }</p> */ }
                    <p>Tel: +88{ shop?.shop_phone }</p>
                    <p>info@abcd.com</p>
                    <p>http://www.yoursite.com</p>
                    <p>Served by { user?.user_username } { user?.user_role }</p>
                    <div className=' w-1/2 py-2 mx-auto my-3 border-2 border-dashed border-black'>
                        <p className='text-xl  text-black'>SALES RECEIPT</p>
                    </div>

                    {
                        allProduct?.map( ( product, i ) =>
                            <div key={ i } className='flex justify-between'>
                                <h1>{ product?.product_name }</h1>
                                <div className='flex gap-5 justify-between w-1/3'>
                                    <p>Qty:{ product?.quantity ? product?.quantity : 1 } </p>
                                    <p>৳ { product?.updatedPrice }</p>
                                </div>
                            </div>
                        )
                    }

                    <hr className='border-dashed border-b-2 border-x-0 border-t-0 border-[1px] my-1' />
                    <div className='flex justify-between text-black font-bold'>
                        <h1>Sub Total:</h1>
                        <p>৳ { order?.total_amount }</p>
                    </div>
                    <div className='flex justify-between mt-1'>
                        <h1>Discount Percentage</h1>
                        <p>{ order?.discount_percentage }%</p>
                    </div>
                    <div className='flex justify-between mt-1'>
                        <h1>Discount Amount</h1>
                        <p>৳ { order?.discount_amount }</p>
                    </div>
                    <div className='flex justify-between'>
                        <h1>Tax Percentage</h1>
                        <p>{ order?.tax_percentage }%</p>
                    </div>
                    <div className='flex justify-between'>
                        <h1>Tax Amount</h1>
                        <p>৳ { order?.tax_amount }</p>
                    </div>

                    <hr className='border-dashed border-b-2 border-x-0 border-t-0 border-[1px] my-1' />
                    <div className='flex justify-between text-black font-bold'>
                        <h1>Total:</h1>
                        <p>৳ { order?.actual_amount }</p>
                    </div>
                    <div className='flex justify-between mt-1'>
                        <h1>{ payMethod[ 0 ] ? payMethod[ 0 ][ 0 ].toUpperCase() + payMethod[ 0 ].slice( 1 ) : order?.payment_type[ 0 ].toUpperCase() + order?.payment_type.slice( 1 ) }</h1>

                        {
                            payCash ?
                                <p>৳ { payCash ? payCash : order?.actual_amount }</p> :
                                <p>৳ { order?.actual_amount }</p>

                        }
                    </div>
                    <div className='flex justify-between'>
                        <h1>Change</h1>
                        <p>৳ { change }</p>
                    </div>
                    <hr className='border-dashed border-b-2 border-x-0 border-t-0 border-[1px] my-1' />
                    <h1 className='text-2xl font-medium text-black mt-2'>Thank You!</h1>
                    <div className='border border-dashed border-black px-4 my-2 mx-auto flex justify-center'>
                        <Barcode value={ `Order No: 0${ format( date, 'dd' ) }-0${ format( date, 'MM' ) }-00${ order?.order_id }` } width={ 1.05 } height={ 50 } displayValue={ true } />
                    </div>
                    <p>{ `Order No: 0${ format( date, 'dd' ) }-0${ format( date, 'MM' ) }-00${ order?.order_id }` }</p>
                    <p>{ today }</p>
                </div>
            </div>
        </div>

    );
};

export default InvoicePdf;