import React, { useContext, useEffect, useState } from 'react';
import { MdDocumentScanner } from "react-icons/md";
import { FaHome, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ProductsContext } from '../../Contexts/ProductContext';

// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// // import "./styles.css";

// // import required modules
// import { Pagination } from "swiper";




const ProductNav = () => {
    const [ categories, setCategories ] = useState( [] );
    const { setSearch } = useContext( ProductsContext );


    const ShopId = localStorage.getItem( 'ShopId' );


    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/product-category/' )
            .then( res => res.json() )
            .then( data => {
                setCategories( data );
                // console.log( data );
            } )
    }, [] );

    const handleSearch = ( e ) => {
        setSearch( e.target.value )

    }




    // const url = 'https://api.zufaa.com/api-zufaa-pos/product/';

    return (
        <div className='pl-10 bg-[#FFFFFF] pr-3 py-2 nav-Shadow'>
            <div className='flex justify-between items-center w-full'>
                <div className='text-lg flex items-center gap-4 w-full'>
                    <Link to={ `/allpos/${ ShopId }` }>
                        <FaHome className='text-2xl font-bold text-[#1083B2]' />
                    </Link>

                    {/* <Link to={ `/allpos/${ ShopId }` }>
                        Chairs
                    </Link> */}

                    {
                        categories?.map( ( category, i ) =>

                            <Link
                                key={ i }
                                to={ `/allpos/category/${ category?.product_category_name }/${ ShopId }/${ category?.product_category_id }` }
                                className='text-[#958B8B] focus:underline focus:underline-offset-8 decoration-2 focus:text-[#FF565E] duration-200'>
                                { category?.product_category_name }
                            </Link> )

                    }

                    {/* <>
                        <Swiper
                            slidesPerView={ 5 }
                            spaceBetween={ 0 }
                            modules={ [ Pagination ] }
                            className="w-full "
                        >

                            {
                                categories?.map( ( category, i ) =>
                                    <SwiperSlide className='w-fit' key={ i }>
                                        <Link key={ i } to={ `/allpos/category/${ category?.product_category_name }/${ ShopId }/${ category?.product_category_id }` } className='text-[#958B8B] focus:underline focus:underline-offset-8 decoration-2 focus:text-[#FF565E] duration-200'>
                                            { category?.product_category_name }
                                        </Link>
                                    </SwiperSlide>
                                )

                            }
                        </Swiper>
                    </> */}


                </div>
                <div className='flex gap-1 items-center bg-[#eeeded]  rounded-md px-4'>
                    <FaSearch className='text-[#A09D9D]' />
                    <input onChange={ handleSearch } type="text" placeholder='Search Items' className='px-2 py-2 bg-transparent focus:outline-none' />
                    <MdDocumentScanner className='text-[#A09D9D] text-2xl' />
                </div>
            </div>
        </div>
    );
};

export default ProductNav;