import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as te from 'tw-elements';
import ProductContext from './Contexts/ProductContext';
import UserProvider from './Contexts/UserProvider/UserProvider';
import CustomerProvider from './Contexts/CustomerProvider/CustomerProvider';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <React.StrictMode>
    <CustomerProvider>
      <UserProvider>
        <ProductContext>
          <App />
        </ProductContext>
      </UserProvider>
    </CustomerProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
