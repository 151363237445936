import React, { useEffect, useRef, useState } from 'react';
import InvoicePdf from '../InvoicePdf/InvoicePdf';
import { FaChevronRight, FaPrint } from 'react-icons/fa';
import { RiSendPlaneFill } from 'react-icons/ri';
import htmlToPdf from 'html-to-pdf-js';
import { useNavigate } from 'react-router-dom';

const PaymentModal = ( { user, allProduct, order, payMethod, payCash, change, date, today, shop } ) => {

    const navigate = useNavigate()
    const ref = useRef( null );


    const handleReceipt = () => {
        // Download Invoice pdf start-------------------

        const input = ref.current;
        const options = { filename: 'Invoice.pdf' };


        htmlToPdf().set( options ).from( input ).save();


        // Download Invoice pdf End-------------------
    }

    const handleNewOrder = () => {
        const ShopId = localStorage.getItem( 'ShopId' )
        localStorage.removeItem( 'products' )
        navigate( `/allpos/${ ShopId }` )
    }


    return (
        <div>
            <input type="checkbox" id="my-modal-3" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-6xl rounded-lg relative py-8 px-5" >
                    <div className='w-full grid grid-cols-5'>
                        <div className='col-span-3 h-full'>
                            <div className='p-2'>
                                <div className='border rounded-lg p-5 h-full flex justify-between flex-col'>
                                    <div>
                                        <div className='flex text-xl justify-between items-center text-[#1083B2]'>
                                            <h1>Total Sales</h1>
                                            <p>BDT ৳ { order?.actual_amount }</p>
                                        </div>
                                        <hr className='my-5' />
                                    </div>

                                    <div className='flex flex-col justify-between gap-6 mt-28'>
                                        <h1 className='text-lg'>How would like to receive your receipt?</h1>

                                        <button onClick={ handleReceipt } className='flex gap-2 text-[#4c4c4c]  bg-[#E3E3E3] w-full justify-center items-center py-4 rounded-md'>
                                            <FaPrint className='text-2xl' />
                                            <span className='font-semibold'>Print Receipt</span>
                                        </button>

                                        <div
                                            className='flex border justify-between border-[#E3E3E3] items-center rounded-md'>
                                            <input
                                                type="email"
                                                placeholder='Email Receipt'
                                                className="bg-transparent border-none w-full focus:outline-none px-5 py-2"
                                            />
                                            <button className='bg-[#E3E3E3] px-4 py-2 m-1 rounded-md'>
                                                {/* <FaUserCircle className='text-2xl text-black' /> */ }
                                                <RiSendPlaneFill className='text-3xl text-black' />
                                            </button>

                                        </div>

                                        <button onClick={ handleNewOrder } className='col-span-4 flex flex-col justify-center items-center gap-3 payment-shadow rounded-md h-40 cursor-pointer'>
                                            <FaChevronRight className='text-5xl rounded-full bg-[#175e6e83] p-2 flex justify-center items-center text-white ' />
                                            <span className='text-lg font-bold text-white'>New Order</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={ ref } className='col-span-2'>
                            <InvoicePdf user={ user } shop={ shop } allProduct={ allProduct } order={ order } payMethod={ payMethod } payCash={ payCash } change={ change } date={ date } today={ today }></InvoicePdf>
                        </div>
                    </div>
                    <div className="modal-action mt-0">
                        <label htmlFor="my-modal-3" className="btn btn-sm btn-circle bg-[#1083B2] border-none absolute right-2 top-2">✕</label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentModal;