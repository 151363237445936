import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// import { ImCross } from 'react-icons/im';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import axios from 'axios';
import ReportDocument from '../../Component/ReportDocument/ReportDocument';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Report = () => {
    const [ shop, setShop ] = useState()
    const [ warehouse, setWarehouse ] = useState();
    const [ report, setReport ] = useState( [] );
    const [ date, setDate ] = useState( '' );


    const shopId = localStorage.getItem( 'ShopId' );
    useEffect( () => {
        fetch( `https://api.zufaa.com/api-zufaa-pos/shop/${ shopId }` )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setShop( data )
                fetch( `https://api.zufaa.com/api-zufaa-pos/warehouse/${ data?.w_id }` )
                    .then( res => res.json() )
                    .then( data => {
                        // console.log( data );
                        setWarehouse( data )
                    } )
            } )
    }, [ shopId ] )



    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = data => {
        // console.log( data );

        const url = 'https://api.zufaa.com/api-zufaa-pos/reporting/'
        axios.post( url, {
            "start_date": data?.start_date,
            "end_date": data?.end_date
        } )
            .then( res => {
                setDate( data )
                setReport( res?.data )
            } )

    };

    return (
        <div>
            {/* Topbar------------ */ }
            <div className='shadow-lg px-10 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-5 items-center'>
                        <h1 className='text-xl font-semibold'>Report</h1>

                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* Report Content here----------------- */ }
            <div className='mx-5 rounded-lg nav-Shadow pb-10 mb-10'>
                <h1 className='text-xl px-8 py-5'>Sales Details</h1>
                <hr />
                <form onSubmit={ handleSubmit( onSubmit ) } className='px-8 py-10'>
                    <div className='flex flex-col gap-6 '>
                        <div className='z-50'>
                            <div className='flex gap-4 items-end '>
                                <span className='text-lg font-normal '>Start Data: </span>

                                <div className='border-b-2 w-5/6'>
                                    <input type="date" className='cursor-pointer px-4 py-1 rounded-md focus:outline-none '  { ...register( "start_date", { required: 'Date is Required' } ) } />
                                </div>


                            </div>
                            { errors.start_date && <p className='text-error'>{ errors.start_date?.message }</p> }
                        </div>

                        <div className='z-40'>
                            <div className='flex gap-4 items-end'>
                                <span className='text-lg font-normal'>End Data: </span>
                                <div className='border-b-2 w-5/6'>
                                    <input type="date" className='cursor-pointer px-4 py-1 rounded-md focus:outline-none '  { ...register( "end_date", { required: 'Date is Required' } ) } />
                                </div>

                            </div>
                            { errors.end_date && <p className='text-error'>{ errors.end_date?.message }</p> }
                        </div>
                    </div>
                    <div className="overflow-x-auto mt-8">
                        <table className="table table-compact w-full">
                            <thead className='px-4'>
                                <tr>
                                    <th>Point Of Sale</th>
                                    <th>Warehouse Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{ shop?.shop_name }</td>
                                    <td>{ warehouse?.w_name }</td>
                                    {/* <td className=''><ImCross /></td> */ }
                                </tr>
                                {/* <tr>
                                    <td>Resturent</td>
                                    <td>Quality Control Specialist</td>
                                    <td className=''><ImCross /></td>
                                </tr> */}
                            </tbody>

                        </table>
                    </div>
                    <input type="submit" value="Generate Report" className='bg-[#1083B2] mt-5 btn btn-md border-none' />

                    {/* The button to open modal */ }
                    {/* <button>
                        <label htmlFor="my-modal-3" className='bg-[#1083B2] mt-5 btn btn-md border-none'>Print</label>
                    </button> */}



                </form>



                {/* <ReportModal report={ report } date={ date }></ReportModal> */ }
                {
                    report?.length ?
                        <ReportDocument reset={ reset } shop={ shop } report={ report } setReport={ setReport } date={ date }></ReportDocument>
                        :
                        <></>
                }

            </div>
        </div>
    );
};

export default Report;