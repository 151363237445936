import React, { useContext } from 'react';
import { FaBars, FaUserCircle } from "react-icons/fa";
// import { BsCashStack, BsFillCartPlusFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineLogout } from 'react-icons/ai';
import { UserContext } from '../../Contexts/UserProvider/UserProvider';
import Swal from 'sweetalert2';

const NavBar = () => {
    const { user } = useContext( UserContext );
    const { user_fullname, user_image, user_role } = user;
    const currentRoute = useLocation();
    const navigate = useNavigate()
    // console.log( user );

    const handleLogout = () => {
        Swal.fire( {
            title: 'Do want to Logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No !',
            confirmButtonText: 'Yes!'
        } ).then( ( result ) => {
            if ( result.isConfirmed ) {
                localStorage.removeItem( 'userId' )
                Swal.fire(
                    'Logout Successful!',
                    'Logout',
                    'success'
                )
                navigate( '/login' )
                // window.location.reload();
            }
        } )
    }




    return (
        // <div className='relative'>
        <div className='bg-[#1083B2] shadow-md'>
            <div className=" flex justify-between items-center p-0 w-11/12 mx-auto text-white">

                <div className="">
                    <label htmlFor="my-drawer-2" className="drawer-button lg:hidden">
                        <FaBars className='text-3xl' />
                    </label>
                    <Link to='/' className="font-bold text-xl">Logo Here</Link>
                </div>

                {/* {
                    user && <div className='mr-96 flex gap-5'>
                        <div className='flex gap-2 items-center'>
                            <BsCashStack className='text-xl' />
                            <h1 className=''>Cash In/Out</h1>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <BsFillCartPlusFill className='text-xl' />
                            <h1 className=''>Orders</h1>
                        </div>
                    </div>
                } */}

                <div className="flex gap-2 items-center">

                    {
                        user ?
                            <div className="relative" data-te-dropdown-ref>
                                <div
                                    className="flex gap-2 items-center pb-2 pt-2.5 font-medium cursor-pointer"
                                    id="dropdownMenuButton2"
                                    data-te-dropdown-toggle-ref
                                    aria-expanded="false"
                                    data-te-ripple-init
                                    data-te-ripple-color="light">

                                    {
                                        user?.user_image ?
                                            <div className="avatar">
                                                <div className="w-10 rounded-full">
                                                    <img className='w-10 rounded-full object-cover' src={ user_image } alt='user_Image' />

                                                </div>
                                            </div>
                                            :
                                            <FaUserCircle className='text-3xl' />
                                    }

                                    <h1 className='font-normal'>{ user_fullname && user_fullname }</h1>
                                </div>
                                <ul
                                    className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-[#1083B2] [&[data-te-dropdown-show]]:block p-3 "
                                    aria-labelledby="dropdownMenuButton2"
                                    data-te-dropdown-menu-ref>
                                    <li>
                                        <div
                                            className="block w-full whitespace-nowrap bg-transparent cursor-pointer px-4 py-2 rounded-lg font-semibold hover:bg-[#126c90] duration-300 disabled:pointer-events-none disabled:bg-transparent"
                                            to='/'
                                            data-te-dropdown-item-ref
                                        >{ user_fullname }</div>
                                    </li>
                                    <li>
                                        <div
                                            className="block w-full whitespace-nowrap bg-transparent cursor-pointer px-4 py-2 rounded-lg font-semibold hover:bg-[#126c90] duration-300 disabled:pointer-events-none disabled:bg-transparent"
                                            to='/'
                                            data-te-dropdown-item-ref
                                        >Role: { user_role }</div>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={ handleLogout }
                                            className="block w-full whitespace-nowrap bg-transparent cursor-pointer px-4 py-2 rounded-lg font-semibold hover:bg-[#126c90] duration-300 disabled:pointer-events-none disabled:bg-transparent"
                                            data-te-dropdown-item-ref
                                        >Logout</Link>
                                    </li>

                                </ul>
                            </div>
                            :
                            <Link to='/login'>
                                <div className='flex items-center py-3'>
                                    <FaUserCircle className='text-3xl w-10' />
                                    <h1>Login</h1>
                                </div>
                            </Link>
                    }



                    <div>
                        {
                            currentRoute?.pathname === '/' ?
                                <></> :
                                <Link to='/' className='flex items-center gap-1'>
                                    <AiOutlineLogout className='text-xl font-bold' />
                                    Close
                                </Link>
                        }
                    </div>

                </div>

            </div>
        </div>
        // </div>
    );
};

export default NavBar;