import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaAngleDoubleLeft, FaCloudUploadAlt } from 'react-icons/fa';
import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';
import empty from '../../../Images/UseImage/empty user.webp'
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const CreateUser = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [ userImg, setUserImg ] = useState( '' )
    // const [ selectedFile, setSelectedFile ] = useState( null );
    const [ previewUrl, setPreviewUrl ] = useState( null );
    const navigate = useNavigate();



    const handleImage = ( e ) => {
        const formData = new FormData();
        const image = e.target.files[ 0 ];
        formData.append( "file", image );
        // setSelectedFile( image );

        const url = 'https://api.zufaa.com/api-zufaa-pos/user/upload/';
        fetch( url, {
            method: "POST",
            body: formData,
        } )
            .then( ( response ) => response.json() )
            .then( ( result ) => {
                setUserImg( result[ 0 ] )

                if ( !result[ 0 ] ) {
                    setPreviewUrl( null );
                    return;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    setPreviewUrl( reader.result );
                };
                reader.readAsDataURL( image );
            } )
            .catch( ( error ) => {
                console.error( "Error:", error );
            } );
    }


    const onSubmit = data => {
        // const user = {
        //     "user_fullname": ( data?.name ),
        //     "user_username": ( data?.name ).split( ' ' ).join( '' ).toLowerCase(),
        //     "user_password": data?.password,
        //     "user_phone": data?.phone,
        //     "user_image": userImg,
        //     "user_role": data?.role
        // }
        // console.log( user );

        const userUrl = 'https://api.zufaa.com/api-zufaa-pos/user/create/';
        axios.post( userUrl, {
            "user_fullname": ( data?.name ),
            "user_username": ( data?.name ).split( ' ' ).join( '' ).toLowerCase(),
            "user_password": data?.password,
            "user_phone": data?.phone,
            "user_image": userImg,
            "user_role": data?.role

        } )
            .then( data => {
                Swal.fire(
                    'Successful',
                    'User Created Successfully',
                    'success'
                )
                reset();
                navigate( '/users' )
            } )
            .then( error => {
                console.log( error );
            } )

    }


    return (
        <div>
            {/* Topbar------------ */ }
            <div className='shadow-lg pl-5 pr-10 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <Link to='/users' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-xl font-semibold'>Users / New</h1>
                        {/* <button className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Create</button> */ }
                    </div>

                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* User Create file here---------------- */ }
            <form onSubmit={ handleSubmit( onSubmit ) } className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                <div className=' flex gap-3'>
                    <div className='flex flex-col gap-4 w-2/3'>
                        <div>
                            <input type="text" placeholder='User Name' className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "name",
                                { required: 'User Name is Required' }
                            ) } />
                            { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                        </div>
                        <div>
                            <input type="number" placeholder='Phone Number' className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "phone",
                                { required: 'Phone Number is Required' }
                            ) } />
                            { errors.phone && <p className='text-error'>{ errors.phone?.message }</p> }
                        </div>
                        <div>
                            <input type="password" placeholder='Password' className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "password",
                                { required: 'Password is Required' }
                            ) } />

                            { errors.password && <p className='text-error'>{ errors.password?.message }</p> }
                        </div>

                        <div>
                            <select { ...register( "role",
                                { required: 'Role is Required' }
                            ) } className='border-b-2 w-full px-4 py-2 focus:outline-none ' >
                                <option value=''>Select Role</option>
                                <option>Admin</option>
                                <option>Manager</option>
                                <option>Seller</option>
                            </select>
                            { errors.role && <p className='text-error'>{ errors.role?.message }</p> }
                        </div>
                    </div>

                    <div className='w-1/3 mx-auto flex gap-2 flex-col justify-center items-center'>
                        <div className='flex flex-col gap-3 w-60'>
                            {/* <img className='border rounded-lg ' src={ chair } alt="" /> */ }
                            { previewUrl ?
                                <img className='border h-60 object-cover rounded-lg ' src={ previewUrl } alt="" />
                                :
                                <div>
                                    <img className='border h-60 object-cover rounded-lg ' src={ empty } alt="" />
                                    <p className='text-error text-center'>Only allowed  png, jpg, and jpeg</p>
                                </div>
                            }
                            {/* <input type="file" name="" id="" /> */ }
                            <label htmlFor="upload-photo" className='flex gap-2 items-center cursor-pointer border rounded-md justify-center'>
                                Upload Your Photo
                                <FaCloudUploadAlt className='text-4xl  rounded-lg' />
                            </label>
                            <input onChange={ handleImage } type="file" name="photo" id="upload-photo" className='hidden' />
                        </div>

                    </div>
                </div>
                <input type="submit" value="Create" className='btn w-full hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
            </form>
        </div>
    );
};

export default CreateUser;