import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaAngleDoubleLeft, FaCamera, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';

const CreateCustomer = () => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();


    const onSubmit = data => {
        // console.log( data );

        // const url = 'https://api.zufaa.com/api-zufaa-pos/customer/';
        axios.post( 'https://api.zufaa.com/api-zufaa-pos/customer/',
            {
                "customer_name": data?.name,
                "customer_email": data?.email
            }
        )
            .then( data => {
                Swal.fire(
                    'Good job!',
                    'Customer Created',
                    'success'
                )
                // console.log( data );
                reset()
            } )
            .then( error => {
                console.log( error );
            } )
    }

    return (
        <div>
            <div className='shadow-lg pl-5 pr-8 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <Link to='/customers' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-2xl font-semibold'>Customers</h1>
                        {/* <button className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Save</button> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* User Create file here---------------- */ }
            <div className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                {/* <div className='flex gap-3'>
                    <div className='flex gap-2'>
                        <input type="checkbox" name="" className='w-5' />
                        Individual
                    </div>
                    <div className='flex gap-2'>
                        <input type="checkbox" name="" className='w-5' />
                        Company
                    </div>
                </div> */}
                <div className='flex'>
                    <form onSubmit={ handleSubmit( onSubmit ) } className='mt-12 flex flex-col w-2/3 gap-5'>

                        <div>
                            <div className='border flex gap-2 items-center rounded-md px-3 w-full'>
                                <FaUser className='text-2xl text-gray-500' />
                                <div className="divider border"></div>
                                <input type="text" placeholder='Full Name' className='focus:outline-none w-full' { ...register( "name",
                                    { required: 'Name is Required' }
                                ) } />

                            </div>
                            { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                        </div>

                        {/* Customer Phone */ }
                        {/* <div className='border flex gap-2 items-center rounded-md px-3 w-full'>
                            <FaPhoneAlt className='text-2xl text-gray-500' />
                            <div className="divider border"></div>
                            <input type="number" placeholder='Phone Number' className='focus:outline-none w-full' />
                        </div> */}

                        <div>
                            <div className='border flex gap-2 items-center rounded-md px-3 w-full'>
                                <FaEnvelope className='text-2xl text-gray-500' />
                                <div className="divider border"></div>
                                <input type="Email" placeholder='Email ' className='focus:outline-none ' { ...register( "email",
                                    { required: 'Email is Required' }
                                ) } />
                            </div>
                            { errors.email && <p className='text-error'>{ errors.email?.message }</p> }

                        </div>
                        {/* <div className='border flex gap-2 items-center rounded-md px-3 w-full'>
                            <FaMapMarkerAlt className='text-2xl text-gray-500' />
                            <div className="divider border"></div>
                            <input type="text" placeholder='Location ' className='focus:outline-none ' />
                        </div> */}


                        <input type="submit" value="Save" className='btn border-none hover:bg-[#0b5977] duration-500 bg-[#1083B2]' />
                    </form>

                    {/* <div className='w-1/3 flex justify-center items-center '>
                        <FaCamera className='text-8xl w-1/2 h-1/2 opacity-50 border p-2 rounded-lg' />
                    </div> */}
                </div>
            </div>

        </div>
    );
};

export default CreateCustomer;