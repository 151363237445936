import React from 'react';

const Orders = ( { order, i, handleOrder, orderProducts } ) => {

    // console.log( orderProducts );
    return (
        <div
            onClick={ () => handleOrder( order?.order_id ) }
            key={ i }
            className="border-b">
            <h2 className="mb-0" id={ `heading${ i }` }>
                <button
                    className="group relative flex w-full items-center bg-white px-5 py-3 text-left text-base text-neutral-800 transition"
                    type="button"
                    data-te-collapse-init
                    data-te-collapse-collapsed
                    data-te-target={ `#collapse${ i }` }
                    aria-expanded="false"
                    aria-controls={ `collapse${ i }` }>
                    <div className='w-full flex justify-between'>
                        <h1>{ i + 1 }</h1>
                        <h1 className='-ml-12'>Order Id: { order?.order_id }</h1>
                        <h1 className='-ml-10'>{ order?.actual_amount } ৳ </h1>
                        <h1 className='text-success'>{ order?.payment_type }</h1>
                        <h1>{ ( order?.created_at ).split( ' ' )[ 0 ] }</h1>
                    </div>

                </button>
            </h2>
            <div
                id={ `collapse${ i }` }
                className="!visible hidden bg-slate-200"
                data-te-collapse-item
                aria-labelledby={ `heading${ i }` }
                data-te-parent="#accordionExample">
                <div className="px-5 py-4 border-t">
                    <div className='grid grid-cols-4 gap-5'>
                        <h1>Order Id: <span className='font-semibold'> { order?.order_id }</span></h1>
                        <h1>Customer Id: <span className='font-semibold'> { order?.customer_id }</span></h1>
                        <h1>Actual Amount: <span className='font-semibold'> { order?.actual_amount } ৳</span> </h1>
                        <h1>Discount Amount: <span className='font-semibold'> { order?.discount_amount } ৳</span></h1>
                        <h1>Discount Percent: <span className='font-semibold'> { order?.discount_percentage } %</span></h1>
                        <h1>Tax Amount: <span className='font-semibold'> { order?.tax_amount } ৳</span></h1>
                        <h1>Tax Percent: <span className='font-semibold'> { order?.tax_percentage } %</span></h1>
                        <h1>Total Amount: <span className='font-semibold'> { order?.total_amount } ৳</span></h1>
                    </div>
                    {/* <div className='flex justify-between mt-4'> */ }
                    <hr className='my-5 border border-gray-400' />
                    <div className='font-semibold grid grid-cols-3 text-center mx-10 mb-2'>
                        <div className='text-left'>
                            <h1>Product Name</h1>
                        </div>
                        {/* <div className='text-left'> */ }
                        <h1>Quantity</h1>
                        {/* </div> */ }
                        <div className='text-right'>
                            <h1>Price</h1>
                        </div>
                    </div>

                    <hr className='mx-10 my-1 border border-gray-400' />
                    <div className='mb-5'>
                        {
                            orderProducts?.map( ( order, i ) =>

                                <div key={ i } className='grid grid-cols-3 text-center mx-10 mb-2'>
                                    <div className='text-left'>
                                        <h1 className='font-semibold'><span>{ i + 1 }</span>: { order?.product_name }</h1>
                                    </div>
                                    {/* <div className='text-left'> */ }
                                    <h1>{ order?.quantity }</h1>
                                    {/* </div> */ }
                                    <div className='text-right'>
                                        <h1>{ order?.price } ৳</h1>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/* </div> */ }
                </div>
            </div>
        </div>
    );
};

export default Orders;