import React, { useEffect, useState } from 'react';
import { FaAngleDoubleLeft, FaCloudUploadAlt } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';

const UpdateShop = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [ warehouses, setWarehouses ] = useState( [] );
    const [ warehouse, setWarehouse ] = useState( {} )
    const [ shop, setShop ] = useState();
    const [ productName, SetProductName ] = useState( '' );
    const [ shopImg, setShopImg ] = useState( '' )
    const [ previewUrl, setPreviewUrl ] = useState( null );
    const [ refresh, setRefresh ] = useState( false );
    const id = useParams();
    const [ error, setError ] = useState()


    // Get single Shop details--------------
    const url = `https://api.zufaa.com/api-zufaa-pos/shop/${ id?.id }`
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setShop( data );
            } )
    }, [ url, refresh ] );

    // Get all warehouse------------
    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/warehouse/' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                const warehouse = data.find( element => element?.w_id === shop?.w_id );
                setWarehouse( warehouse )
                setWarehouses( data );
            } )
    }, [ shop?.w_id ] )

    const onSubmit = data => {

        axios.put( 'https://api.zufaa.com/api-zufaa-pos/shop/', {
            "shop_id": shop?.shop_id,
            "shop_name": data?.name ? data.name : shop?.shop_name,
            "shop_address": data?.address ? data.address : shop?.shop_address,
            "shop_phone": data?.phone ? data.phone : shop?.shop_phone,
            "shop_logo": shopImg ? shopImg : shop?.shop_logo,
            "w_id": shop?.w_id
        } )
            .then( data => {
                // console.log( data );
                Swal.fire(
                    'Good job!',
                    'Shop is Update Successful',
                    'success'
                )
                // navigate( '/' )
                reset()
                setRefresh( !refresh )
            } )
            .then( error => {
                setError( error );
            } )

    }


    const handleImage = ( e ) => {
        const formData = new FormData();
        const image = e.target.files[ 0 ];
        formData.append( "file", image );
        // setSelectedFile( image );

        const url = ' https://api.zufaa.com/api-zufaa-pos/shop/upload/';
        fetch( url, {
            method: "POST",
            body: formData,
        } )
            .then( ( response ) => response.json() )
            .then( ( result ) => {
                setShopImg( result[ 0 ] )

                if ( !result[ 0 ] ) {
                    setPreviewUrl( null );
                    return;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    setPreviewUrl( reader.result );
                    if ( image.name.length > 15 ) {
                        SetProductName( image.name.slice( 0, 10 ) + '...' );
                        console.log( image.name.slice( 0, 15 ) + '...' );
                    }
                };
                reader.readAsDataURL( image );
            } )
            .catch( ( error ) => {
                console.error( "Error:", error );
            } );


    }



    return (
        <div>
            {/* Topbar add---------------- */ }
            <div className='shadow-lg px-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <Link to='/' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-xl font-semibold'>Update Shop</h1>
                        {/* <button className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Save</button> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* Shop content here-------------- */ }

            <form onSubmit={ handleSubmit( onSubmit ) } className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                <div className=' flex gap-3'>
                    <div className='flex flex-col gap-6 w-2/3'>
                        <div>
                            <label htmlFor="">Shop Name:</label>
                            <input type="text" defaultValue={ shop?.shop_name } className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "name",
                                // { required: "Shop Name is Required" }
                            ) } />
                            {/* { errors.name && <p className='text-error'>{ errors.name?.message }</p> } */ }
                        </div>
                        <div>
                            <label htmlFor="">Shop Address:</label>
                            <input type="text" defaultValue={ shop?.shop_address } className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "address",
                                // { required: 'Address is Required' }
                            ) } />
                            { errors.address && <p className='text-error'>{ errors.address?.message }</p> }
                        </div>
                        <div>
                            <label htmlFor="">Shop Phone:</label>
                            <input type="number" defaultValue={ shop?.shop_phone } className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "phone",
                                // { required: 'Phone is Required' }
                            ) } />
                            {/* { errors.phone && <p className='text-error'>{ errors.phone?.message }</p> } */ }
                        </div>

                        {/* Get all WareHouse-------------------- */ }
                        <div>
                            <label htmlFor="">Select Warehouse: </label>
                            <select { ...register( "warehouse_id" ) }
                                className='border-b-2 mt-1 w-full px-4 py-2 focus:outline-none '
                            // defaultValue='Product Category'

                            >
                                <option value={ warehouse?.w_name }>{ warehouse?.w_name }</option>
                                {
                                    warehouses?.map( ( warehouse, i ) => <option value={ warehouse?.w_id } key={ i }>{ warehouse?.w_name }</option> )
                                }

                            </select>
                            {/* { errors.category_id && <p className='text-error'>{ errors.category_id?.message }</p> } */ }
                        </div>

                    </div>

                    <div className='w-1/3 mx-auto flex gap-2 flex-col justify-center items-center'>
                        <div className=' flex flex-col gap-3 w-60 '>

                            {/* <img className='border rounded-lg ' src={ shop?.shop_logo } alt="" /> */ }
                            {
                                previewUrl ?
                                    <img className='border object-cover p-2 rounded-lg w-full h-full' src={ previewUrl } alt="" />
                                    :
                                    <div>
                                        <img className='border object-cover p-2 rounded-lg w-full' src={ shop?.shop_logo } alt="" />
                                        <p className='text-success text-center'>Only allowed  png, jpg, and jpeg</p>
                                    </div>
                            }
                            <label htmlFor="upload-photo" className='flex gap-2 items-center cursor-pointer border rounded-md justify-center'>
                                { productName ? productName : 'Upload Your Logo' }
                                <FaCloudUploadAlt className='text-4xl  rounded-lg' />
                            </label>
                            <input onChange={ handleImage } type="file" name="photo" id="upload-photo" className='hidden' />
                        </div>

                    </div>
                </div>
                <input type="submit" value="Update" className='btn w-full hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
            </form>
            {
                error &&
                <p className='text-error'>{ error }</p>
            }


        </div>
    );
};

export default UpdateShop;