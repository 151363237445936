import React, { useContext, useState } from 'react';
import bgimg from '../Images/UseImage/Untitled design (88) 1.png'
import logo from '../Images/Logo/Group 178.png'
import { FaEnvelope, FaLock, } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { UserContext } from '../Contexts/UserProvider/UserProvider';
// import { ProductsContext } from '../Contexts/ProductContext';

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [ toggle, setToggle ] = useState( false )
    const navigate = useNavigate();
    const { setUserId } = useContext( UserContext );
    const [ error, setError ] = useState( '' )

    const onSubmit = ( data ) => {

        const url = 'https://api.zufaa.com/api-zufaa-pos/user/login/'

        axios.post( url, {
            "user_username": ( data?.name ).split( ' ' ).join( '' ).toLowerCase(),
            "user_password": data?.password
        } )
            .then( data => {
                if ( data?.data.status ) {
                    localStorage.setItem( 'userId', data?.data.user_id );
                    navigate( '/' );
                    setUserId( data?.data.user_id );
                }
                else {
                    setError( data?.data.message )
                }

            } )

    }



    const handlebtn = () => {
        setToggle( !toggle )
    }

    return (
        <div className='relative flex justify-center items-center'>
            <img className='w-full object-fit min-h-screen' src={ bgimg } alt="" />

            <div className='bg-white p-8 absolute top-24 rounded-lg '>
                <img className='mb-3 mx-auto' src={ logo } alt="" />
                <h1 className='text-3xl text-[#1083B2] font-semibold'>Login</h1>
                <hr className='border-2 w-20 mt-1 rounded-full border-[#1083B2]' />

                <form onSubmit={ handleSubmit( onSubmit ) } className='mt-5 flex flex-col gap-2'>
                    <div className='border flex gap-2 items-center rounded-md px-3 w-96'>
                        <FaEnvelope className='text-2xl text-gray-500' />
                        <div className="divider border"></div>
                        <input type="text" placeholder='User Name' className='focus:outline-none ' { ...register( "name",
                            { required: 'Name is Required' }
                        ) } />
                    </div>
                    { errors.email && <p className='text-error'>{ errors.email?.message }</p> }
                    <div className='border flex gap-2 items-center rounded-md px-3 w-96'>
                        <FaLock className='text-2xl text-gray-500' />
                        <div className="divider border"></div>
                        <input type="password" placeholder='Password' className='focus:outline-none ' { ...register( "password",
                            { required: 'Password Required' }
                        ) } />
                    </div>
                    {
                        error && <p className='text-error'>{ error }</p>
                    }
                    { errors.password && <p className='text-error'>{ errors.password?.message }</p> }
                    <div className='flex gap-3 items-center'>
                        <h1>Keep me logged in</h1>
                        <input onChange={ handlebtn } type="checkbox" className="toggle toggle-xs toggle-success" />
                    </div>
                    <input type="submit" value="Login" className='btn border-none hover:bg-[#0b5977] duration-500 bg-[#1083B2]' />
                </form>
            </div>
        </div>
    );
};

export default Login;