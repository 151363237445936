import React, { useContext, useEffect, useState } from 'react';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import { ProductsContext } from '../../Contexts/ProductContext';
import Orders from './Orders/Orders';

const AllOrders = () => {
    const [ orders, setOrders ] = useState( [] );
    const { search } = useContext( ProductsContext );
    const [ searchOrders, setSearchOrders ] = useState( [] );
    const [ orderProducts, setOrderProducts ] = useState( [] );



    // Get all orders----------------------
    const url = 'https://api.zufaa.com/api-zufaa-pos/order-table/';

    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                const searchOrders = data.filter( order => order?.order_id.toLowerCase().includes( search.toLowerCase() ) );
                setSearchOrders( searchOrders )
                setOrders( data );
            } )
    }, [ search ] );

    // Get All Order Item----------------------
    // const itemUrl = 'https://api.zufaa.com/api-zufaa-pos/order-item/';
    // useEffect( () => {
    //     fetch( itemUrl )
    //         .then( res => res.json() )
    //         .then( data => {
    //             // console.log( data );
    //         } )
    // }, [] )



    const handleOrder = ( id ) => {

        fetch( `https://api.zufaa.com/api-zufaa-pos/order-item/order-id/${ id }` )
            .then( res => res.json() )
            .then( orderProducts => {
                // console.log( orderProducts );

                fetch( 'https://api.zufaa.com/api-zufaa-pos/reporting/' )
                    .then( res => res.json() )
                    .then( allProducts => {

                        const filterProduct = allProducts.filter( product => orderProducts.some( order => product?.order_id === order?.order_id ) );
                        // console.log( filterProduct );
                        setOrderProducts( filterProduct );
                    } )

            } )


    }


    return (
        <div className='mb-16'>
            {/* Topbar------------ */ }
            <div className='shadow-lg pl-5 pr-10 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-5 items-center'>
                        <h1 className='text-2xl font-semibold'>All Orders:{ orders?.length }</h1>
                        {/* <Link to='/' className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Back</Link> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>
            {/* All Orders--------------------- */ }



            {/* <div className="overflow-x-auto">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Order Id</th>
                            <th>Total Amount</th>
                            <th>Payment Type</th>
                            <th>order Date</th>
                        </tr>
                    </thead>
                    <tbody>


                        {
                            orders.map( ( order, i ) =>
                                <tr key={ i }>
                                    <th>{ i + 1 }</th>
                                    <td>Order Id: { order?.order_id }</td>
                                    <td>{ order?.actual_amount } ৳ </td>
                                    <td>{ order?.payment_type }</td>
                                    <td>{ ( order?.created_at ).split( ' ' )[ 0 ] }</td>

                                </tr> )
                        }
                    </tbody>
                </table>
            </div> */}

            <div className='font-semibold flex justify-between px-6 bg-[#f8f5f5] py-4'>
                <h1>SL</h1>
                <h1>Order Id</h1>
                <h1>Total Amount</h1>
                <h1>Payment Type</h1>
                <h1>order Date</h1>
            </div>


            <div id="accordionExample">

                {
                    searchOrders?.length ?
                        searchOrders?.map( ( order, i ) => <Orders key={ i } i={ i } order={ order } handleOrder={ handleOrder } orderProducts={ orderProducts }></Orders> )
                        :
                        orders?.map( ( order, i ) => <Orders key={ i } i={ i } order={ order } handleOrder={ handleOrder } orderProducts={ orderProducts }></Orders> )
                }

            </div>


        </div>
    );
};

export default AllOrders;