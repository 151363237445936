import React, { useContext } from 'react';
import { FaAlignJustify, FaFilter, FaLayerGroup, FaRegClock, FaSearch } from 'react-icons/fa';
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import { ProductsContext } from '../../Contexts/ProductContext';
import icon from '../../Images/Icon/Group 38.png'

const DashboardNavBar = () => {
    const { setSearch } = useContext( ProductsContext );

    const handleSearch = ( e ) => {
        setSearch( e.target.value )
    }

    return (
        <div className='flex justify-between items-center gap-5'>

            {/* <input type="text" placeholder="Type here" className="input w-full border-b-2" /> */ }
            <div className='flex gap-2 items-center border-b px-6 pb-1 rounded-lg'>
                <FaSearch className='text-[#7A7979]' />
                <div className="divider m-0 h-6 border"></div>
                <input onChange={ handleSearch } type="text" placeholder='Search' className='focus:outline-none ' />
            </div>

            <div className='flex gap-4 items-center text-[#7A7979]'>
                <div className='flex gap-1 items-center'>
                    <FaFilter className='' />
                    <span>Filters</span>
                </div>
                <div className='flex gap-1 items-center'>
                    <FaLayerGroup className='' />
                    <span>Group By</span>
                </div>
                {/* <div className='flex gap-1 items-center'>
                    <FaStar className='' />
                    <span>Favorites</span>
                </div> */}
            </div>

            <div className='flex gap-2 items-center'>
                <p className='text-[#7A7979]'>1-24 / 24</p>
                <div className='flex gap-1 items-center'>
                    <TfiAngleLeft className='' />
                    <TfiAngleRight className='text-lg ' />
                </div>
            </div>

            <div className='flex gap-3 items-center'>
                <img className='w-4' src={ icon } alt="" />
                <FaAlignJustify />
                <FaRegClock />
            </div>
        </div>
    );
};

export default DashboardNavBar;