import DashboardNavBar from '../../../Shared/DashboardNavBar/DashboardNavBar';
import { FaAngleDoubleLeft, FaCloudUploadAlt } from 'react-icons/fa';
import empty from '../../../Images/UseImage/41190_empty_256x256.png'
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';


const AddCategories = () => {
    const [ previewUrl, setPreviewUrl ] = useState( null );
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

    const onSubmit = data => {
        // const category = {
        //     "product_category_name": data?.name
        // }
        // console.log( category );

        const url = 'https://api.zufaa.com/api-zufaa-pos/product-category/';

        axios.post( url,
            {
                "product_category_name": data?.name
            }
        )
            .then( res => {
                Swal.fire(
                    'Good job!',
                    'Category Added !',
                    'success'
                )
                // console.log( res );
                reset()
            } )
            .catch( error => {
                console.log( error );
            } )


    };


    return (
        <div>
            <div className='shadow-lg px-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-4 items-center'>
                        <Link to='/productCategory' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='font-semibold'>Product Categories / New</h1>
                        {/* <button className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Save</button> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/*Add Categories---------------- */ }
            <form
                onSubmit={ handleSubmit( onSubmit ) }
                className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                <div className=' flex gap-3'>
                    <div className='flex flex-col gap-6 w-2/3'>
                        <div>
                            <input
                                type="text"
                                placeholder='Category Name'
                                className='border-b-2 w-full px-4 py-2 focus:outline-none '
                                { ...register( "name",
                                    { required: "Name is Required" }
                                ) } />
                            { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                        </div>

                        {/* <input type="text" placeholder='Parent Category' className='border-b-2 w-full px-4 py-2 focus:outline-none ' /> */ }

                    </div>

                    <div className='w-1/3 mx-auto flex gap-2 flex-col'>
                        <div className='flex flex-col justify-center items-center gap-3'>

                            {
                                previewUrl ?
                                    <img className='border w-full h-72 object-cover rounded-lg ' src={ previewUrl } alt="" />
                                    :
                                    <div className='flex flex-col justify-center items-center'>
                                        <img className='border h-32 w-32 object-cover rounded-lg ' src={ empty } alt="" />
                                        <p className='text-accent text-center'>Only allowed  png, jpg, and jpeg</p>
                                    </div>

                            }
                            <label htmlFor="upload-photo" className='flex gap-2 w-36  items-center border rounded-md justify-center'>
                                Upload Photo
                                <FaCloudUploadAlt className='text-4xl rounded-lg' />
                            </label>
                            <input type="file" id="upload-photo" className='hidden' />
                        </div>

                    </div>

                </div>
                <input type="submit" value="Save" className='btn w-full hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
                {/* <button className='btn btn-wide rounded-md bg-[#1083B2] border-none mt-5'>Save</button> */ }
            </form>
        </div>
    );
};

export default AddCategories;