import axios from 'axios';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { FaBackspace, FaChevronCircleRight, FaInfoCircle, FaReply, FaUserCircle, FaUserPlus } from 'react-icons/fa';
import { ImCross } from "react-icons/im";
import { RiStickyNoteFill } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ProductsContext } from '../../Contexts/ProductContext';
import { UserContext } from '../../Contexts/UserProvider/UserProvider';
import noImage from '../../Images/UseImage/noImage.png'



const Buttons = [
    {
        value: 1
    },
    {
        value: 2
    },
    {
        value: 3
    },
    {
        value: 4
    },
    {
        value: 5
    },
    {
        value: 6
    },
    {
        value: 7
    },
    {
        value: 8
    },
    {
        value: 9
    },
    {
        value: 0
    },
    {
        value: '.'
    },
    {
        value: '+/-'
    }
]



const ProductCart = () => {
    const { refresh, setRefresh, warehouse } = useContext( ProductsContext );
    const [ customers, setCustomers ] = useState( [] );
    const [ products, setProducts ] = useState( [] );
    const [ toggle, setToggle ] = useState( true );
    const navigate = useNavigate()
    const { user } = useContext( UserContext );
    const [ customerId, setCustomerId ] = useState( '1' );
    const [ totalPrice, setTotalPrice ] = useState( 0 );
    const [ actualAmount, setActualAmount ] = useState( 0 )
    const [ discAmount, setDiscAmount ] = useState();
    const [ discTotalAmount, setDiscTotalAmount ] = useState( 0 )
    const [ tax, setTax ] = useState()
    const [ discount, setDiscount ] = useState()
    const [ taxPercentage, setTaxPercentage ] = useState();
    const [ discPercentage, setDiscPercentage ] = useState();
    const [ totalTaxAmount, setTotalTaxAmount ] = useState();
    const [ cDiscount, SetCDiscount ] = useState( 0 )



    const date = new Date()
    const today = format( date, 'iiii, d LLL, YYY' );

    // Get all Customers------------------------------------
    const customerUrl = 'https://api.zufaa.com/api-zufaa-pos/customer/'
    useEffect( () => {
        fetch( customerUrl )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setCustomers( data );
            } )
    }, [] )

    // Get Tax ----------------------
    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/tax/' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data[ 0 ] );
                setTax( data[ 0 ] );
                // setTaxPercentage( parseFloat( data[ 0 ].tax_percentage ) )
            } )
    }, [] )

    // Get Discount------------------------
    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/discount/' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data[ 0 ] );
                setDiscount( data[ 0 ] );
                // setDiscPercentage( parseFloat( data[ 0 ].amount ) )
            } )
    }, [] )

    // console.log( tax );
    // console.log( discount );

    const calculateBtns = [
        {
            btn: <FaBackspace className='text-2xl' />,
            value: ''
        },
        {
            btn: 'Tax',
            value: tax?.tax_percentage,
            percent: `${ tax?.tax_percentage * 100 }%`,
        },
        {
            btn: '%Disc',
            value: discount?.amount,
            percent: `${ discount?.amount * 100 }%`,
        },
        {
            btn: 'Code',
            value: '',
            percent: '',
        },
        {
            btn: 'Custom Discount',
            value: `${ cDiscount }`,
            percent: `${ cDiscount ? cDiscount : 0 }%`
        },
    ]


    const hadleDelete = ( id ) => {
        const data = localStorage.getItem( "products" );
        const allProducts = data ? JSON.parse( data ) : [];

        const uniqueArry = allProducts.filter( product => product?.product_id !== id );

        const updatedData = JSON.stringify( uniqueArry );
        localStorage.setItem( 'products', updatedData )
        setRefresh( !refresh )
    }

    const handleCustomer = ( e ) => {
        const customer_id = ( e.target.value ).split( ' ' )[ 1 ];
        setCustomerId( customer_id ? customer_id : 0 );
    }

    const handlebtn = ( e ) => {
        // console.log( e );
        // setQuantity( e )
    }

    const handleOrder = () => {

        // const order = {
        //     "user_id": user?.user_id,
        //     "customer_id": customerId,
        //     "w_id": warehouse && warehouse,
        //     "payment_type": "cash",
        //     "total_amount": totalPrice && totalPrice,
        //     "discount_percentage": ( cDiscount ? cDiscount : discPercentage ) ? ( cDiscount ? cDiscount : discPercentage ) : 0,
        //     "discount_amount": discAmount ? discAmount : 0,
        //     "tax_percentage": taxPercentage ? taxPercentage : 0,
        //     "tax_amount": totalTaxAmount ? totalTaxAmount : 0,
        //     "actual_amount": actualAmount ? actualAmount : totalPrice
        // }

        // console.log( order );

        if ( products.length > 0 ) {
            const url = 'https://api.zufaa.com/api-zufaa-pos/order-table/';

            axios.post( url, {
                "user_id": user?.user_id,
                "customer_id": customerId,
                "w_id": warehouse && warehouse,
                "payment_type": "cash",
                "total_amount": totalPrice && totalPrice,
                "discount_percentage": ( cDiscount ? cDiscount : discPercentage ) ? ( cDiscount ? cDiscount : discPercentage ) : 0,
                "discount_amount": discAmount ? discAmount : 0,
                "tax_percentage": taxPercentage ? taxPercentage : 0,
                "tax_amount": totalTaxAmount ? totalTaxAmount : 0,
                "actual_amount": actualAmount ? actualAmount : totalPrice
            } )

                .then( res => {
                    const orderId = ( res.data?.order_id );

                    // console.log( res );
                    // Create Order Item------------------------
                    const allProduct = localStorage.getItem( "products" );
                    const products = allProduct ? JSON.parse( allProduct ) : [];

                    for ( const product of products ) {
                        // console.log( product );

                        const url = 'https://api.zufaa.com/api-zufaa-pos/order-item/'
                        axios.post( url, {
                            "order_id": orderId && orderId,
                            "product_id": product?.product_id,
                            "quantity": product?.quantity,
                            "price": product?.updatedPrice
                        } )
                            .then( res => {
                                // console.log( res );
                                navigate( `/payment/${ orderId }` )
                            } )
                            .catch( error => {
                                console.log( error );
                            } )
                    }

                } )
                .catch( error => {
                    // console.log( error );
                    Swal.fire(
                        'Ooops!',
                        `${ error.response.data.message }<br> Please Login`,
                        'error',
                    )
                } )
        }
        else {
            Swal.fire(
                'Ooops!',
                'Please add Products',
                'error',
            )
        }


    }


    // // Get total price -----------------
    // useEffect( () => {
    //     const data = localStorage.getItem( "products" );
    //     const products = data ? JSON.parse( data ) : [];


    //     let totalPrice = 0;
    //     for ( const product of products ) {

    //         totalPrice = totalPrice + ( + product?.updatedPrice );
    //     }

    //     setTotalPrice( totalPrice.toFixed( 2 ) );


    // }, [ refresh, toggle ] )



    // Get all Data form LocalStorage..............
    useEffect( () => {

        const data = localStorage.getItem( "products" );

        const products = data ? JSON.parse( data ) : [];
        setProducts( products );

        // price calculate-----------
        let totalPrice = 0;
        for ( const product of products ) {

            totalPrice = totalPrice + ( + product?.updatedPrice );
        }

        setTotalPrice( totalPrice.toFixed( 2 ) );


    }, [ refresh, toggle ] )



    const handleQuantityChange = ( e, index ) => {
        // e.preventDefault()
        const quantity = parseInt( e.target.value ) ? parseInt( e.target.value ) : 0;

        if ( quantity > 0 ) {
            const updatedPrice = quantity * products[ index ].product_selling_price;


            setProducts( ( prevState ) => {

                const updatedProducts = [ ...prevState ];

                updatedProducts[ index ] = {
                    ...updatedProducts[ index ],
                    quantity,
                    updatedPrice,
                };

                const updateStorage = JSON.stringify( updatedProducts );
                localStorage.setItem( 'products', updateStorage );
                setToggle( !toggle )
                return updatedProducts;
            } );
        }

        else {
            const updatedPrice = 1 * products[ index ].product_selling_price;


            setProducts( ( prevState ) => {

                const updatedProducts = [ ...prevState ];

                updatedProducts[ index ] = {
                    ...updatedProducts[ index ],
                    quantity,
                    updatedPrice,
                };

                const updateStorage = JSON.stringify( updatedProducts );
                localStorage.setItem( 'products', updateStorage );
                setToggle( !toggle )
                return updatedProducts;
            } );
        }

    }

    const handleCalculate = ( btn, value ) => {

        if ( btn === '%Disc' ) {
            const percent = value

            setDiscPercentage( value * 100 )

            SetCDiscount()

            const disc = ( totalPrice * percent ).toFixed( 2 )
            setDiscAmount( disc )
            setTotalTaxAmount( 0 )

            const actualPrice = ( totalPrice - ( + disc ) ).toFixed( 2 );

            setActualAmount( actualPrice );
            setDiscTotalAmount( actualPrice )

        }
        else if ( btn === 'Tax' ) {

            const taxPercentage = value;

            setTaxPercentage( value * 100 )

            const amount = actualAmount ? actualAmount : totalPrice;

            const taxAmount = ( amount * taxPercentage ).toFixed( 2 )

            setTotalTaxAmount( taxAmount )

            const actualPrice = ( ( + amount ) + ( + taxAmount ) ).toFixed( 2 )

            setActualAmount( actualPrice );
            // return
        }
        else if ( btn === 'Custom Discount' ) {
            setDiscAmount( 0 )
            SetCDiscount( value )
        }
        else if ( btn.key === null ) {
            setDiscAmount( 0 );
            SetCDiscount( 0 );
            setTotalTaxAmount( 0 );
            setTaxPercentage( 0 )
            setActualAmount( totalPrice );
        }
        // return
    }

    const handleCustomDiscount = ( e ) => {
        e.preventDefault()
        // const value = +( e.target.value );
        const value = +( e.target.discount.value )

        SetCDiscount( value )
        const percentage = ( value ) / 100;


        const disc = ( totalPrice * percentage ).toFixed( 2 )
        setDiscAmount( disc )
        setTotalTaxAmount( 0 )

        const actualPrice = ( totalPrice - ( + disc ) ).toFixed( 2 );

        setActualAmount( actualPrice );
        setDiscTotalAmount( actualPrice )


    }

    const [ selectProduct, setSelectProductProduct ] = useState()

    const handleProduct = ( product ) => {
        setSelectProductProduct( product )
    }


    return (
        <div className='bg-[#FFFFFF] flex flex-col justify-between rounded-b-lg'>
            <div>
                <div className='px-3 py-2 bg-[#1083B2]'>
                    <div className='flex text-white justify-between items-center'>
                        <div className='flex w-1/2 gap-2 items-center'>
                            <div
                                className='flex border border-[#19b2efc1] items-center rounded-md gap-3 text-white px-2 py-1'>
                                <FaUserCircle className='text-2xl' />
                                <input
                                    list="customer"
                                    type="text"
                                    onChange={ ( e ) => handleCustomer( e ) }
                                    placeholder='Select Customer'
                                    className="bg-transparent border-none w-full placeholder-white focus:outline-none cursor-pointer"
                                />
                                <datalist id="customer" className='w-full'>

                                    {
                                        customers.map( ( customer, i ) => <option key={ i } value={ `${ customer?.customer_email } ${ customer?.customer_id } ` }></option> )
                                    }
                                </datalist>

                            </div>
                        </div>

                        <div className='py-2'>
                            <Link to='/createCustomer'>
                                <div className='flex items-center gap-1'>
                                    <FaUserPlus className='text-2xl font-bold' />
                                    <h1>Add Customer</h1>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className='mx-4 pt-4 rounded-lg'>
                    <div className='flex justify-between items-end'>
                        <h1 className='font-normal'>New Order Bill</h1>
                        <p className='text-sm text-[#898989]'>{ today }</p>
                    </div>
                </div>
                <hr className='my-2 mx-4' />

                <div className='h-60 overflow-y-scroll'>
                    {
                        products?.map( ( product, index ) =>
                            <div key={ index }>
                                <div
                                    onClick={ () => handleProduct( product ) }
                                    className={ selectProduct?.product_id === product?.product_id ? 'flex justify-between py-2 pr-5 bg-slate-200' : 'flex justify-between py-2 pr-5 cursor-pointer' }>
                                    {/* className='flex justify-between mr-5'> */ }
                                    <div
                                        // onClick={ () => handleProduct( product ) }
                                        // onSubmit={ ( e ) => handleQuantityChange( e, index ) }
                                        className='flex justify-between w-full items-center text-[#474747] mx-4 '>
                                        <div className='flex gap-4 items-center'>
                                            <div className='rounded-lg bg-[#F0F0F0] p-1'>
                                                <img src={ product?.product_image || noImage } alt="" className='w-10 h-10 object-cover rounded-lg' />
                                            </div>
                                            <div className='text-sm font-medium'>
                                                <h1>{ product?.product_name }</h1>
                                                <h2>৳ { product?.product_selling_price }</h2>
                                            </div>
                                        </div>

                                        <div className='flex justify-between items-center gap-5'>

                                            <input name='quantity' type="text"
                                                onChange={ ( e ) => handleQuantityChange( e, index ) }
                                                // defaultValue={ product?.quantity }

                                                value={ product?.quantity }
                                                placeholder={ product?.quantity ? product?.quantity : '' }
                                                className='bg-gray-300 text-center px-2 w-14 h-10 rounded-md placeholder:text-gray-400' />
                                            <div>
                                                <p>৳ { parseFloat( product.updatedPrice ).toFixed( 2 ) || parseFloat( product.product_selling_price ).toFixed( 2 ) }</p>
                                            </div>

                                        </div>
                                    </div>
                                    <button onClick={ () => hadleDelete( product?.product_id ) } className='text-gray-500 hover:text-error'>
                                        <ImCross className='text-xs' />
                                    </button>
                                </div>
                                <hr className='mx-4' />
                                {/* <hr className='my-2 mx-4' /> */ }
                            </div>
                        )
                    }
                </div>

            </div >

            {/* Button section---------------- */ }
            <div>
                <hr className='border-dashed border-1 border-black mt-10' />
                <div className='mx-4 my-2'>
                    <div className='flex justify-end mr-5'>
                        {/* <h1 className='text-lg font-bold py-2'>Total: ৳ { totalPrice ? totalPrice : 0 }</h1> */ }
                        <div className='flex flex-col text-right'>
                            <h1 className='text-lg font-bold'>Total: ৳ { totalPrice ? totalPrice : 0 }</h1>
                            {
                                cDiscount ?
                                    <form
                                        onSubmit={ handleCustomDiscount }
                                        className='flex gap-2'>
                                        <label className='text-lg text-success font-bold'>Add Discount:</label>
                                        <input autoFocus type="text" name='discount' className='border border-success rounded-md w-12 text-success focus:outline-none px-2' />
                                        <span className='text-success'>%</span>
                                    </form >
                                    :
                                    <></>
                            }
                            {
                                discAmount ?
                                    <>
                                        <h1 className='text-lg text-success font-bold'>Discount: ৳ { discAmount ? discAmount : 0 }</h1>
                                        <h1 className='text-lg font-bold'>DiscTotal: ৳ { discTotalAmount ? discTotalAmount : 0 }</h1>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                totalTaxAmount ?
                                    <>
                                        <h1 className='text-lg text-success font-bold'>Tax: ৳ { totalTaxAmount ? totalTaxAmount : 0 }</h1>
                                        <h1 className='text-lg font-bold'>Actual: ৳ { actualAmount ? actualAmount : 0 }</h1>
                                    </>
                                    :
                                    <></>
                            }

                        </div>
                    </div>

                </div>

                <div className='mx-2 mb-4 grid grid-cols-3 gap-2'>

                    <button className='px-2 py-3 text-white text-center bg-[#28DF9D] border-none rounded-md '>
                        <div className='flex gap-1 items-center justify-center'>
                            <RiStickyNoteFill className='text-lg' />
                            <span className='text-sm'>Customer Note</span>
                        </div>
                    </button>


                    {/* set Customer here in button--------------------------- */ }

                    {/* <div
                        onChange={ ( e ) => handleCustomer( e ) }
                        className='flex bg-[#28DF9D] items-center rounded-md gap-1 text-white px-2'>
                        <RiStickyNoteFill className='text-xl' />
                        <input
                            list="customer"
                            type="text"
                            onChange={ ( e ) => handleCustomer( e ) }
                            placeholder="Customer"
                            className=" bg-transparent w-full border-none px-0 placeholder-white focus:outline-none"
                        />
                        <datalist id="customer" className='w-full'>

                            {
                                customers.map( ( customer, i ) => <option key={ i } value={ `৳ { customer?.customer_email } ৳ { customer?.customer_id } ` }></option> )
                            }
                        </datalist>

                         <select className='w-full bg-transparent focus:outline-none' >
                            <option value="" className='text-black px-5'>Customers</option>
                            {
                                customers.map( ( customer, i ) =>
                                    <option key={ i }
                                        className='text-black px-5'
                                        value={ customer?.customer_id }
                                    >
                                        { customer?.customer_email }
                                    </option> )
                            }
                        </select> 

                    </div> */}

                    <button className='px-4 py-3 text-white text-center bg-[#508CB8] border-none rounded-md '>
                        <div className='flex gap-1 items-center justify-center'>
                            <FaReply className='text-xl' />
                            <span className='text-base'>Refund</span>
                        </div>
                    </button>

                    <button className='px-4 py-3 text-white text-center bg-info border-none rounded-md '>
                        <div className='flex gap-1 items-center justify-center'>
                            <FaInfoCircle className='text-xl' />
                            <span className='text-base'>Info</span>
                        </div>
                    </button>

                </div>

                {/* Calculate section ----------------------- */ }
                <div className='mx-2 flex gap-2 pb-2'>
                    <div className='grid grid-cols-3 gap-2 w-2/5'>
                        {
                            Buttons?.map( ( button, i ) =>
                                <button onClick={ () => handlebtn( button?.value ) } key={ i } className=' text-2xl h-14 border shadow-md rounded-lg'>{ button?.value }</button>
                            )
                        }
                    </div>
                    <div className='grid grid-cols-4 grid-rows-4 gap-2 w-3/5'>

                        {/* {
                            calculateBtns?.map( ( btn, i ) => <button key={ i } onClick={ () => handleCalculate( btn?.btn, btn?.value ) } className=' font-semibold h-14 border shadow-md rounded-lg flex justify-center items-center relative'>
                                <span className='text-[10px] font-thin absolute top-0 right-1'>{ btn?.percent && btn?.percent } </span>
                                <span>{ btn?.btn }</span>
                            </button> )
                        } */}
                        {
                            calculateBtns?.map( ( btn, i ) => <button key={ i } onClick={ () => handleCalculate( btn?.btn, btn?.value ) } className={ `${ btn?.btn === 'Custom Discount' ? 'font-semibold col-span-4 bg-[#8ccee8] hover:bg-[#60b1d1] duration-500 hover:text-white h-14 border shadow-md rounded-lg flex justify-center items-center relative' : 'font-semibold h-14 border shadow-md rounded-lg flex justify-center items-center relative' }` }>
                                <span className='text-[10px] font-thin absolute top-0 right-1'>{ btn?.percent && btn?.percent } </span>
                                <span>{ btn?.btn }</span>
                            </button> )
                        }


                        <div onClick={ handleOrder } className='col-span-4 row-span-4 font-medium border bg-[#1083B2] shadow-md rounded-lg flex flex-col justify-center items-center text-black text-xl cursor-pointer'>
                            <FaChevronCircleRight className='text-6xl opacity-40' />
                            <span className='text-white'>Payment</span>
                        </div>

                    </div>
                </div>
            </div >
        </div >
    );
};

export default ProductCart;