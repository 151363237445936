import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaAngleDoubleLeft, FaBackspace, FaChevronRight, FaRegFileAlt, FaUser } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ProductsContext } from '../../Contexts/ProductContext';
import cash from '../../Images/paymentIcon/Frame 33.png'
import bkash from '../../Images/paymentIcon/Frame 38.png'
import DBBL from '../../Images/paymentIcon/Frame 39.png'
import rocket from '../../Images/paymentIcon/Frame 40.png'
import bank from '../../Images/paymentIcon/Frame 37.png'
import { ImCross } from 'react-icons/im';
import Barcode from 'react-barcode';
import { format } from 'date-fns';
import axios from 'axios';
import InvoicePdf from '../../Component/InvoicePdf/InvoicePdf';
import htmlToPdf from 'html-to-pdf-js';
import { UserContext } from '../../Contexts/UserProvider/UserProvider';
import PaymentModal from '../../Component/PaymentModal/PaymentModal';



const methods = [
    {
        icon: cash,
        value: 'cash'
    },
    {
        icon: bkash,
        value: 'bkash'
    },
    {
        icon: DBBL,
        value: 'DBBL'
    },
    {
        icon: rocket,
        value: 'rocket'
    },
    {
        icon: bank,
        value: 'bank'
    }
]
const Buttons = [
    {
        value: 1
    },
    {
        value: 2
    },
    {
        value: 3
    },
    {
        value: '+10'
    },
    {
        value: 4
    },
    {
        value: 5
    },
    {
        value: 6
    },
    {
        value: '+20'
    },
    {
        value: 7
    },
    {
        value: 8
    },
    {
        value: 9
    },
    {
        value: '+50'
    },
    {
        value: '+/-'
    },
    {
        value: 0
    },
    {
        value: '.'
    },
    {
        value: <FaBackspace className='text-3xl' />
    },

]

const Payment = () => {
    const { user } = useContext( UserContext )
    const id = useParams();
    const [ order, setOrder ] = useState();
    const [ payMethod, setPayMethod ] = useState( [ 'cash', cash ] );
    const [ change, setChange ] = useState( 0 );
    const [ payCash, setPayCash ] = useState();
    const [ orderNote, setOrderNote ] = useState( '' );
    const [ shop, setShop ] = useState()


    const ShopId = localStorage.getItem( 'ShopId' );

    const shopId = localStorage.getItem( 'ShopId' );
    useEffect( () => {
        fetch( `https://api.zufaa.com/api-zufaa-pos/shop/${ shopId }` )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setShop( data )
            } )
    }, [ shopId ] )

    const data = localStorage.getItem( 'products' );
    const allProduct = data ? JSON.parse( data ) : [];

    const date = new Date()
    const today = format( date, 'dd/MM/YYY p' );


    // get Single Order------------------------
    useEffect( () => {
        fetch( `https://api.zufaa.com/api-zufaa-pos/order-table/${ id?.id }` )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setOrder( data )
            } )
    }, [ id?.id ] )

    const handlePaymentMethod = ( icon, value ) => {
        if ( value !== 'cash' ) {
            setPayCash( order?.actual_amount )
            setChange( 0 )
        }
        setPayMethod( [ value, icon ] );
    }

    const handlePayment = ( e ) => {
        e.preventDefault()
        const form = e.target.amount;
        const cash = form.value
        setPayCash( cash )

        const change = ( cash - order?.actual_amount ).toFixed( 2 );
        setChange( change )
    }

    const handleNote = ( e ) => {
        setOrderNote( e.target.value )
    }

    // const ref = useRef( null );


    const handleOrder = () => {
        // console.log( payMethod[ 0 ] );

        const updateOrder = {
            "order_id": order?.order_id,
            "user_id": order?.user_id,
            "customer_id": order?.customer_id,
            "w_id": order?.w_id,
            "payment_type": payMethod ? payMethod[ 0 ] : order?.payment_type,
            "total_amount": order?.total_amount,
            "discount_percentage": order?.discount_percentage,
            "discount_amount": order?.discount_amount,
            "tax_percentage": order?.tax_percentage,
            "tax_amount": order?.total_amount,
            "actual_amount": order?.actual_amount
        }
        // order Update----------------------
        const updateUrl = 'https://api.zufaa.com/api-zufaa-pos/order-table/';

        axios.put( updateUrl, updateOrder )
            .then( data => {
                // console.log( data );
            } )
            .catch( error => {
                console.log( error );
            } )

        // payment------------
        const url = 'https://api.zufaa.com/api-zufaa-pos/payment/';
        axios.post( url, {
            "order_id": order?.order_id,
            "amount": order?.actual_amount,
            "method": payMethod[ 0 ],
            "remarks": orderNote && orderNote
        } )
            .then( res => {
                // console.log( res );
            } )
            .catch( error => {
                console.log( error );
            } )
    }


    // const handleBack = () => {
    //     localStorage.removeItem( 'products' )
    //     navigate( `/allpos/${ ShopId }` )
    // }


    return (
        <div className='pb-10 shadow-lg'>
            <div className='py-2 flex justify-between items-center px-8 shadow-md'>
                <Link to={ `/allpos/${ ShopId }` } className="btn bg-transparent hover:bg-[#0c678b] border-none hover:text-white duration-700 border-[#1083B2] btn-sm text-[#1083B2] flex justify-start gap-1">
                    <FaAngleDoubleLeft />
                    <span>Back</span>
                </Link>
                <h1 className='text-2xl font-semibold pr-24 text-[#1083B2]'>Payment</h1>
                {/* {
                    complerOrder ?
                        <button onClick={ handleBack } className="btn bg-transparent hover:bg-[#0c678b] border-none hover:text-white duration-700 border-[#1083B2] btn-sm text-[#1083B2] flex justify-start  gap-2">
                            <FaAngleDoubleLeft />
                            <span>Go to Order Page</span>
                        </button>
                        :
                        <div></div>
                } */}
                <div></div>
            </div>
            <div className='px-8 py-3 grid grid-cols-3 gap-4'>
                <div className=' shadow-md'>
                    <div className='pb-10'>
                        <div className=' bg-[#1083B2] px-7 py-3'>
                            <h1 className='text-white font-normal'>Payment Method</h1>
                        </div>
                        {
                            methods?.map( ( method, i ) =>
                                <div key={ i } className='flex flex-col'>
                                    <div onClick={ () => handlePaymentMethod( method?.icon, method?.value ) } className={ `${ payMethod[ 0 ] === method?.value && 'bg-slate-200 flex items-center' } ` }>
                                        <img className='px-4 py-2' src={ method?.icon } alt="" />
                                        {/* <img onClick={ () => handlePaymentMethod( method?.icon, method?.value ) } className='px-4 py-2' src={ method?.icon } alt="" /> */ }
                                    </div>
                                    <hr className=' w-full' />
                                </div>
                            )
                        }
                        <div className='bg-[#dfdddd]'>
                            <h1 className='px-6 py-2 font-medium'>Summery</h1>
                        </div>
                        <div className='py-3 pr-5 flex justify-between items-center'>
                            <div className='my-2'>
                                {
                                    payMethod[ 0 ] === 'cash' ?
                                        < img className='my-2 px-4' src={ payMethod[ 1 ] } alt="" />
                                        :
                                        < img className='my-2 px-4' src={ payMethod[ 1 ] } alt="" />

                                }
                            </div>
                            <div className='flex gap-5 items-center'>
                                {/* {
                                    payMethod[ 0 ] === 'cash' ?
                                        // console.log( parseFloat( order?.actual_amount ).toFixed( 2 ) )
                                        <input type="number"
                                            defaultValue={ order?.actual_amount }
                                            className='text-end w-32 px-5'
                                        />
                                        :
                                        <p>৳ { parseFloat( order?.actual_amount ).toFixed( 2 ) }</p>
                                } */}
                                {/* <ImCross /> */ }
                                <p>৳ { order?.actual_amount }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className='bg-[#965454] h-44 text-white px-10 py-10 w-full mx-auto'>
                        {
                            // order?.payment_type === 'cash' || payMethod[ 0 ] === 'cash' ?
                            payMethod[ 0 ] === 'cash' ?
                                <form onSubmit={ handlePayment } className='flex items-center gap-2'>
                                    <span className='text-3xl'>BDT ৳</span>
                                    <input name='amount'
                                        type="text"
                                        placeholder={ order?.actual_amount }
                                        autoFocus
                                        className='bg-transparent text-4xl w-2/3 border rounded-lg focus:outline-none placeholder:text-white px-3' />
                                </form>
                                :
                                <h1 className='text-4xl '>BDT { ( order?.actual_amount ) }</h1>
                        }
                        <h1 className='text-xl mt-5'>Change:৳ <span className='text-success'>{ change }</span></h1>
                        <h1 className=' text-xl'> Payment method: <span className=' font-semibold text-success'>{ payMethod[ 0 ][ 0 ].toUpperCase() + payMethod[ 0 ].slice( 1 ) }</span></h1>
                    </div>
                    <div className='my-2' >
                        {/* <input onChange={ handleNote } type="text" placeholder='Add Note of Customer' className='p-3 w-full border-2 border-[#1083B2] focus:outline-none' /> */ }
                        <textarea onChange={ handleNote } placeholder='Add Note of Customer' className='p-3 w-full border-2 border-[#1083B2] focus:outline-none h-14' ></textarea>
                    </div>
                    <div className='grid grid-cols-4 gap-4'>
                        {
                            Buttons?.map( ( button, i ) =>
                                <button
                                    key={ i } className='text-3xl font-bold flex justify-center items-center w-24 h-24 border bg-[#F1F4F6] rounded-lg shadow-md'>{ button?.value }</button>
                            )
                        }


                        {/* Generate Pdf--------------- */ }
                        <label onClick={ handleOrder }
                            htmlFor="my-modal-3"
                            className='col-span-4 flex justify-center items-center gap-3 payment-shadow h-24 cursor-pointer'>
                            <span className='text-lg font-bold text-white'>Pay</span>
                            <FaChevronRight className='text-5xl rounded-full bg-[#175e6e83] p-2 flex justify-center items-center text-white ' />
                        </label>

                        {/* <button onClick={ handleDownload }>Download PDF</button> */ }



                    </div>
                </div>
                <div className='nav-Shadow'>
                    <div className='px-7 py-2 flex gap-3 items-center text-xl'>
                        <FaUser />
                        <span>Customer</span>
                    </div>

                    <hr />
                    <div className='px-7 py-2 flex gap-3 items-center text-xl'>
                        <FaRegFileAlt />
                        <span>Invoice</span>
                    </div>
                    <hr />


                    {/* PDF Generate here--------------------- */ }

                    <InvoicePdf user={ user } shop={ shop } allProduct={ allProduct } order={ order } payMethod={ payMethod } payCash={ payCash } change={ change } date={ date } today={ today }></InvoicePdf>


                    <PaymentModal user={ user } shop={ shop } allProduct={ allProduct } order={ order } payMethod={ payMethod } payCash={ payCash } change={ change } date={ date } today={ today } ></PaymentModal>

                </div>
            </div>
        </div >
    );
};

export default Payment;