import { format } from 'date-fns';
import React, { useRef } from 'react';
import { FaPrint } from 'react-icons/fa';
import htmlToPdf from 'html-to-pdf-js';

const ReportDocument = ( { report, setReport, date, shop, reset } ) => {

    const ref = useRef( null );


    const totalPrice = report.reduce( ( sum, product ) => {
        return sum + ( + product.TotalSales );
    }, 0 );

    const sortedData = report.slice().sort( ( a, b ) => {
        const dateA = new Date( a.Date );
        const dateB = new Date( b.Date );
        return dateA - dateB;
    } );

    const todayDate = new Date()
    const today = format( todayDate, 'dd-MM-YYY p' );

    const handleReport = () => {
        const input = ref.current;
        const options = { filename: 'Invoice.pdf' };
        htmlToPdf().set( options ).from( input ).save();
        reset()
        setReport( [] )
    }


    return (
        <div className='relative '>
            <div className='flex justify-end pr-14 absolute -top-6 right-1'>
                <button onClick={ handleReport } className='items-center flex gap-2 hover:text-[#1083B2] duration-500'>
                    <FaPrint />
                    Print Report
                </button>
            </div>
            <div ref={ ref } className='border text-center  rounded-md border-black text-black mx-14 my-10'>
                <h1 className='text-left pl-1'>{ today }</h1>
                <h1 className='text-xl '>{ shop?.shop_name }</h1>
                <h1 className='text-xl '>Address: { shop?.shop_address }</h1>
                <div className='mt-2'>
                    <h1 className='text-3xl'>Sales Details</h1>
                    <p>{ date?.start_date } to { date?.end_date }</p>
                </div>

                <div className="overflow-x-auto rounded-none mt-5">

                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead >
                                <tr className='border-y border-black'>
                                    <th className='py-2 border-r border-black'>SL</th>
                                    <th className='py-2 border-r border-black'>Date</th>
                                    <th className='py-2 border-r border-black'>Product</th>
                                    <th className='py-2 border-r border-black'>Quantity</th>
                                    <th className='py-2  border-black'>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sortedData.map( ( product, i ) =>
                                        <tr key={ i } className='border-b border-black'>
                                            <th className='py-2 border-r border-black'>{ i }</th>
                                            <th className='py-2 border-r border-black font-normal'>{ product?.Date }</th>
                                            <td className='py-2 border-r border-black'>{ product?.ProductName }</td>
                                            <td className='py-2 border-r border-black'>{ product?.Quantity }</td>
                                            <td className='py-2  border-black'>৳ { product?.TotalSales }</td>
                                        </tr>
                                    )
                                }
                            </tbody>

                        </table>
                    </div>



                    <div className='text-left px-10 my-5 text-black bg-stone-300 py-2 flex justify-between'>
                        <p className='text-lg font-bold '>Total Price:</p>
                        <p className='text-lg font-bold '>৳  { totalPrice.toFixed( 2 ) }</p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ReportDocument;