import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProductsContext } from '../../Contexts/ProductContext';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import { UserContext } from '../../Contexts/UserProvider/UserProvider';
import SingleProduct from './SingleProduct/SingleProduct';


const Products = () => {
    const [ products, setProducts ] = useState( [] );
    const [ searchProduct, setSearchProduct ] = useState( [] );
    const { search } = useContext( ProductsContext );
    const { user } = useContext( UserContext )


    // get All Products.---------------
    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/product/' )
            .then( res => res.json() )
            .then( data => {
                if ( search.length ) {
                    const products = data.filter( product => product?.product_name.toLowerCase().includes( search.toLowerCase() ) );
                    setSearchProduct( products )
                }
                setProducts( data )
            } )
    }, [ search ] )

    return (
        <div>
            {/* Topbar------------ */ }
            <div className='shadow-lg pl-5 pr-10 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <h1 className='text-2xl font-semibold'>Products</h1>
                        {
                            user?.user_role === 'Admin' || user?.user_role === 'Manager' ?
                                <Link to='/addProduct' className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Add Product</Link>
                                :
                                <></>
                        }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>

            {/* All Products--------------------- */ }
            <div className='grid grid-cols-3 gap-2 px-8 mb-10'>
                {
                    searchProduct.length ?

                        searchProduct?.map( ( product, i ) => <SingleProduct key={ i } product={ product } ></SingleProduct> )
                        :
                        products?.map( ( product, i ) => <SingleProduct key={ i } product={ product } ></SingleProduct> )

                }
            </div>
        </div>
    );
};

export default Products;