import React, { useContext } from 'react';
import { ProductsContext } from '../../Contexts/ProductContext';
import noImage from '../../Images/UseImage/noImage.png'

const Product = ( { product } ) => {
    const { refresh, setRefresh } = useContext( ProductsContext );
    const { product_image, product_name, product_selling_price } = product;

    const handleOrder = ( product ) => {
        const orderProduct = {
            created_at: product?.created_at,
            product_buying_price: product?.product_buying_price,
            product_category_id: product?.product_category_id,
            product_description: product?.product_description,
            product_id: product?.product_id,
            product_image: product?.product_image,
            product_name: product?.product_name,
            product_selling_price: product?.product_selling_price,
            updated_at: product?.updated_at,
            updatedPrice: product?.product_selling_price,
            quantity: 1
        }

        const existingData = localStorage.getItem( "products" );
        let existingObjects = existingData ? JSON.parse( existingData ) : [];

        const objectExists = existingObjects?.find( ( product ) => product.product_id === orderProduct.product_id );


        if ( !objectExists ) {
            existingObjects.push( orderProduct );
        }
        else {
            const rest = existingObjects.filter( exist => exist.product_id !== orderProduct.product_id );
            objectExists.quantity = objectExists.quantity + 1;
            objectExists.updatedPrice = objectExists.quantity * objectExists.product_selling_price;
            existingObjects = [ objectExists, ...rest ]

            setRefresh( !refresh );
        }

        const updatedData = JSON.stringify( existingObjects );
        localStorage.setItem( 'products', updatedData )
        setRefresh( !refresh )

    }


    return (
        // <div className='rounded-lg'>
        <div onClick={ () => handleOrder( product ) } className='shadow-md bg-[#FFFFFF] rounded-lg p-2'>
            <div className='bg-[#F6F5F5] p-2 rounded-lg mb-2'>
                <img src={ product_image || noImage } alt="" className='w-24 h-24 object-cover rounded-lg mx-auto' />
            </div>
            <div className='flex flex-col gap-3 mx-1'>
                <h1 className='text-[#474747]'>{ product_name }</h1>
                <hr className='border' />
                <p className=' font-bold text-[#30011E]'>Price:৳ { product_selling_price }</p>
                {/* <p className=' font-bold text-[#30011E]'>Price:৳ <span className='text-lg font-semibold'>{ product_selling_price }</span></p> */ }
            </div>
        </div>
        // </div>
    );
};

export default Product;