import React, { useEffect, useState } from 'react';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';

const UpdateTax = () => {
    const [ tax, setTax ] = useState( {} );
    const [ refresh, setRefreshi ] = useState( false )


    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/tax/' )
            .then( res => res.json() )
            .then( data => {
                setTax( data[ 0 ] )
            } )
    }, [ refresh ] )


    const onSubmit = data => {
        // console.log( data );
        const tax_percent = data.tax_percentage / 100;

        // // const tax = {
        // //     "tax_name": data?.name,
        // //     "tax_percentage": tax_percent
        // // }

        // console.log( tax );

        const url = 'https://api.zufaa.com/api-zufaa-pos/tax/';
        axios.put( url, {

            "tax_id": tax?.tax_id,
            "tax_name": data?.name,
            "tax_percentage": tax_percent && tax_percent

        } )
            .then( res => {
                Swal.fire(
                    'Good job!',
                    'Tax_Percentage Updated',
                    'success'
                )
                reset();
                setRefreshi( !refresh )
            } )
            .catch( error => {
                // console.log( error );
            } )




    }

    return (
        <div>
            {/* Topbar add---------------- */ }
            <div className='shadow-lg px-5 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                        <Link to='/' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-xl font-semibold'>Add Tax</h1>
                        {/* <button className='btn btn-sm rounded-md bg-[#1083B2] border-none'>Save</button> */ }
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>


            <form onSubmit={ handleSubmit( onSubmit ) } className='px-8 py-12 mx-5 rounded-lg nav-Shadow flex flex-col justify-center'>
                <div className=' flex gap-3'>
                    <div className='flex flex-col gap-6 w-1/2 mx-auto'>
                        <div>
                            <label htmlFor="">Tax Name:</label>
                            <input type="text" defaultValue={ tax?.tax_name && tax?.tax_name } placeholder={ tax?.tax_name && tax?.tax_name } className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "name",
                                { required: "Name is Required" }
                            ) } />
                            { errors.name && <p className='text-error'>{ errors.name?.message }</p> }
                        </div>
                        <div>
                            <label htmlFor="">Tax percentage:</label>
                            <input type="number" placeholder={ `${ tax?.tax_percentage * 100 }%` } className='border-b-2 w-full px-4 py-2 focus:outline-none ' { ...register( "tax_percentage",
                                { required: 'Tax_percentage is Required' }
                            ) } />
                            { errors.tax_percentage && <p className='text-error'>{ errors.tax_percentage?.message }</p> }
                        </div>

                    </div>

                </div>
                <input type="submit" value="Add Tax" className='btn w-1/2 mx-auto hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
                {/* <button className='btn btn-wide rounded-md bg-[#1083B2] border-none mt-5'>Save</button> */ }
            </form>

        </div>
    );
};

export default UpdateTax;