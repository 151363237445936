import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Product from '../../../Component/Product/Product';
import { ProductsContext } from '../../../Contexts/ProductContext';


// const products = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, ]


const AllProducts = () => {
    const [ products, setProducts ] = useState( [] );
    const id = useParams();
    const { search, setWareHouse } = useContext( ProductsContext );
    const [ searchProduct, setSearchProduct ] = useState( [] );

    const ShopId = localStorage.getItem( 'ShopId' );

    // get single shop ------------------------
    useEffect( () => {
        fetch( `https://api.zufaa.com/api-zufaa-pos/shop/${ id?.id || ShopId }` )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setWareHouse( data?.w_id )
                // setProductsId( id?.id );
                localStorage.setItem( 'ShopId', id?.id )
            } )
    }, [ id, ShopId, setWareHouse ] )



    // Get all Products-----------------
    useEffect( () => {
        fetch( 'https://api.zufaa.com/api-zufaa-pos/product/' )
            .then( res => res.json() )
            .then( allProducts => {
                // console.log( allProducts );

                // Get all Inventory Product------------------------------
                fetch( 'https://api.zufaa.com/api-zufaa-pos/inventory/' )
                    .then( res => res.json() )
                    .then( allInventory => {
                        // console.log( allInventory );
                        // console.log( allProducts );

                        // const filteredProducts = allProducts.filter( product => allInventory.filter( inventory => product?.product_id === inventory?.product_id ) );
                        const filteredProducts = allProducts.filter( product => allInventory.some( inventory => product?.product_id === inventory?.product_id ) );
                        // console.log( filteredProducts );

                        if ( search.length ) {
                            const products = filteredProducts.filter( product => product?.product_name.toLowerCase().includes( search.toLowerCase() ) );
                            setSearchProduct( products )
                            return
                        }
                        else {
                            setProducts( filteredProducts )
                            setSearchProduct()
                        }
                    } )


            } )
    }, [ search ] )


    // // Get all Product------------------------------

    // useEffect( () => {
    //     fetch( 'https://api.zufaa.com/api-zufaa-pos/product/' )
    //         .then( res => res.json() )
    //         .then( data => {

    //             if ( search.length ) {

    //                 const products = data.filter( product => product?.product_name.toLowerCase().includes( search.toLowerCase() ) );
    //                 // console.log( products );
    //                 setSearchProduct( products )
    //                 return
    //             }
    //             else {
    //                 setProducts( data )
    //                 setSearchProduct()
    //             }
    //         } )
    // }, [ id, search ] )


    // All Product of Single Shop --------------------



    return (
        <div className='ml-5 mt-5 p-3 rounded-lg'>
            {/* AllProduct:{ products.length } */ }
            <div className='grid grid-cols-4 gap-5'>
                {
                    searchProduct?.length ?

                        searchProduct?.map( ( product, i ) => <Product key={ i } product={ product } ></Product> )
                        :
                        products?.map( ( product, i ) => <Product key={ i } product={ product } ></Product> )

                }

            </div>
        </div>
    );
};

export default AllProducts;