import AddDiscount from "../../Component/AddDiscount/AddDiscount";
import AddTax from "../../Component/AddTax/AddTax";
import UpdateDiscount from "../../Component/UpdateDiscount/UpdateDiscount";
import UpdateTax from "../../Component/UpdateTax/UpdateTax";
import PaymentLayout from "../../Layouts/PaymentLayout/PaymentLayout";
import POSLayout from "../../Layouts/POSLayout/POSLayout";
import Login from "../../Login/Login";
import AllOrders from "../../Pages/AllOrders/AllOrders";
import CreateCustomer from "../../Pages/Customers/CreateCustomer/CreateCustomer";
import Customers from "../../Pages/Customers/Customers/Customers";
import CreateShop from "../../Pages/Dashboard/CreateShop/CreateShop";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import UpdateShop from "../../Pages/Dashboard/UpdateShop/UpdateShop";
import Inventory from "../../Pages/Inventory/Inventory";
import Payment from "../../Pages/Payment/Payment";
import AddCategories from "../../Pages/Products/AddCategories/AddCategories";
import AddProduct from "../../Pages/Products/AddProduct/AddProduct";
import ProductCategory from "../../Pages/Products/ProductCategory/ProductCategory";
import Products from "../../Pages/Products/Products";
import ProductVarient from "../../Pages/ProductVarient/ProductVarient";
import Report from "../../Pages/Report/Report";
import AllProducts from "../../Pages/ShopProducts/AllProducts/AllProducts";
import SingleCategoryProducts from "../../Pages/ShopProducts/SingleCategoryProducts/SingleCategoryProducts";
import CreateUser from "../../Pages/User/CreateUser/CreateUser";
import User from "../../Pages/User/User";
import CreateWarehouse from "../../Pages/WareHouse/Create Warehouse/CreateWarehouse";
import WareHouse from "../../Pages/WareHouse/WareHouse";

const { createBrowserRouter } = require( "react-router-dom" );
const { default: DashboardLayout } = require( "../../Layouts/DashboardLayout/DashboardLayout" );

export const router = createBrowserRouter( [
    {
        path: '/',
        element: <DashboardLayout></DashboardLayout>,
        children: [
            {
                path: '/',
                element: <Dashboard></Dashboard>
            },
            {
                path: '/createShop',
                element: <CreateShop></CreateShop>
            },
            {
                path: '/updateshop/:id',
                element: <UpdateShop></UpdateShop>
            },
            {
                path: '/users',
                element: <User></User>
            },
            {
                path: '/createuser',
                element: <CreateUser></CreateUser>
            },
            {
                path: '/report',
                element: <Report></Report>
            },
            {
                path: '/product',
                element: <Products></Products>
            },
            {
                path: '/inventory/:id',
                element: <Inventory></Inventory>
            },
            {
                path: '/variant/:id',
                element: <ProductVarient></ProductVarient>
            },
            {
                path: '/addProduct',
                element: <AddProduct></AddProduct>
            },
            {
                path: '/productCategory',
                element: <ProductCategory></ProductCategory>
            },
            {
                path: '/addCategory',
                element: <AddCategories></AddCategories>
            },
            {
                path: '/customers',
                element: <Customers></Customers>
            },
            {
                path: '/createCustomer',
                element: <CreateCustomer></CreateCustomer>
            },
            {
                path: '/warehouse',
                element: <WareHouse></WareHouse>
            },
            {
                path: '/createWarehouse',
                element: <CreateWarehouse></CreateWarehouse>
            },
            {
                path: '/addtax',
                element: <AddTax></AddTax>
            },
            {
                path: 'updateTax',
                element: <UpdateTax></UpdateTax>
            },
            {
                path: '/addDiscount',
                element: <AddDiscount></AddDiscount>
            },
            {
                path: '/updateDiscount',
                element: <UpdateDiscount></UpdateDiscount>
            },
            {
                path: '/allorders',
                element: <AllOrders></AllOrders>
            }
        ]
    },
    {
        path: '/allpos',
        element: <POSLayout></POSLayout>,
        children: [

            // {
            //     path: '/allpos',
            //     element: <AllProducts></AllProducts>
            // },

            {
                path: '/allpos/:id',
                element: <AllProducts></AllProducts>
            },
            {
                path: '/allpos/category/:name/:shopId/:ShopId',
                element: <SingleCategoryProducts></SingleCategoryProducts>
            },

        ]
    },
    {
        path: '/payment',
        element: <PaymentLayout></PaymentLayout>,
        children: [
            {
                path: '/payment/:id',
                element: <Payment></Payment>
            }
        ]
    },
    {
        path: '/login',
        element: <Login></Login>
    }
] )