import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import DashboardNavBar from '../../Shared/DashboardNavBar/DashboardNavBar';
import noImage from '../../Images/UseImage/noImage.png'

const Inventory = () => {
    const [ product, setProduct ] = useState( [] );
    const [ warehouses, setWarehouses ] = useState( [] )
    const id = useParams();
    const [ error, setError ] = useState()
    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    const onSubmit = data => {

        const url = 'https://api.zufaa.com/api-zufaa-pos/inventory/'
        axios.post( url, {
            "w_id": data?.warehouse,
            "product_id": product?.product_id,
            "quantity": data?.quantity ? data.quantity : 1
        } )
            .then( data => {
                // console.log( data );
                Swal.fire(
                    'Good job!',
                    'Inventory Created',
                    'success'
                )
                // console.log( data );
                reset()
            } )
            .then( error => {
                setError( error.message )

            } )
    };

    // get Single Porduct--------------
    useEffect( () => {
        fetch( `https://api.zufaa.com/api-zufaa-pos/product/${ id?.id }` )
            .then( res => res.json() )
            .then( data => {
                setProduct( data );
            } )
    }, [ id ] );


    // Get all Warehouses---------------------------
    const warehouseUrl = 'https://api.zufaa.com/api-zufaa-pos/warehouse/'
    useEffect( () => {
        fetch( warehouseUrl )
            .then( res => res.json() )
            .then( data => {
                setWarehouses( data )
                // console.log( data );
            } )
    }, [] )

    return (
        <div>
            {/* Topbar------------ */ }
            <div className='shadow-lg pl-5 pr-10 py-3 mb-5'>
                <div className='flex justify-between'>
                    <div className='flex gap-2 items-center'>
                        <Link to='/product' className=''>
                            <FaAngleDoubleLeft className='text-3xl text-[#1083B2] hover:text-[#339dc7]' />
                        </Link>
                        <h1 className='text-xl font-semibold'>Product / Inventory</h1>
                        <div className='btn btn-sm rounded-md bg-[#1083B2] border-none'>save</div>
                    </div>
                    <DashboardNavBar></DashboardNavBar>
                </div>
            </div>
            <form
                onSubmit={ handleSubmit( onSubmit ) }
                className='px-8 py-12 mx-5 rounded-lg nav-Shadow'>
                <h1 className='text-xl font-semibold'>Add to Inventory</h1>
                <hr className='my-2 border-b-2' />
                <div className=' flex justify-center items-center gap-3 mt-10 '>
                    <div className='flex flex-col gap-6 w-2/3'>

                        <div className='flex justify-between items-end'>
                            <span className='text-lg font-normal'>Warehouse: </span>
                            <select
                                { ...register( "warehouse",
                                    { required: 'Please Select Warehouse' }
                                ) }
                                className='border-b-2 py-2 w-5/6 focus:outline-none'
                            >
                                <option value=''>Warehouse Name</option>
                                {
                                    warehouses?.map( ( warehouse, i ) => <option value={ warehouse?.w_id } key={ i }>{ warehouse?.w_name }</option> )
                                }

                            </select>
                        </div>
                        { errors.warehouse && <p className='text-error'>{ errors.warehouse?.message }</p> }

                        <div className='flex justify-between items-end '>
                            <span className='text-lg font-normal'>Quantity: </span>
                            <input type="number" defaultValue='1' className='border-b-2 py-2 w-5/6 focus:outline-none '
                                { ...register( "quantity" ) }
                            />
                        </div>
                        { errors.quantity && <p className='text-error'>{ errors.quantity?.message }</p> }

                    </div>

                    <div className='flex flex-col gap-2 items-center justify-center w-1/3'>
                        <img src={ product?.product_image || noImage } alt="" className='w-48 h-48 object-cover border rounded-lg' />
                        <div>
                            <h1>{ product?.product_name }</h1>
                            <h1>Price: { product?.product_selling_price }</h1>
                        </div>
                    </div>
                </div>

                <input type="submit" value="Create" className='btn w-full hover:bg-[#139acf] rounded-md bg-[#1083B2] border-none mt-5' />
                {/* <button className='btn btn-wide rounded-md bg-[#1083B2] border-none mt-5'>Save</button> */ }
            </form>
            {
                error && <p>{ error }</p>
            }
        </div>
    );
};

export default Inventory;