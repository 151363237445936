import React, { createContext, useState } from 'react';

export const CustomerContext = createContext();

const CustomerProvider = ( { children } ) => {
    const [ customer, setCustomer ] = useState( '' );


    const customerInfo = { customer, setCustomer }

    return (
        <CustomerContext.Provider value={ customerInfo }>
            { children }
        </CustomerContext.Provider>
    );
};

export default CustomerProvider;